import { createContext, Dispatch } from 'react';
import { IGlobalState, initialState } from 'global-state/IGlobalState';
import { TAction } from 'global-state/action/TAction';

export interface IContext {
  state: IGlobalState;
  dispatch: Dispatch<TAction>;
}

export const AppContext = createContext<IContext>({
  state: initialState,
  dispatch: () => {}
});
