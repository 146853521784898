import React from 'react';
import { useFormControl } from 'hooks/useFormControl';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Button } from 'components/basic/Button/Button';
import { Input } from 'components/basic/Input/Input';
import { Modal } from 'components/basic/Modal/Modal';

interface IDriverModalProps {
  isOpen?: boolean;
  toggle: () => void;
}

export function DriverModal({ isOpen = false, toggle }: IDriverModalProps): React.ReactElement {
  const { t } = usePrefixedTranslation('DriverModal');

  const phone = useFormControl({
    name: 'phone'
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} title={t('title')}>
      <Input t={t} formControl={phone} />

      <div className='modal-buttons'>
        <Button type='PRIMARY' onClick={toggle}>
          {t('submit')}
        </Button>
        <Button type='SECONDARY' onClick={toggle}>
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
}
