export interface IResponseBody<T> {
  success: boolean;
  message: string;
  value?: T;
}

export const SERVER_ERROR_RESPONSE_BODY = {
  success: false,
  message: 'Global.ServerError'
};
