import { useParams } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import {
  faBell,
  faBellSlash,
  faCalendarDays,
  faCheck,
  faCircleInfo,
  faHashtag,
  faHome,
  faImage,
  faLocationDot,
  faMoneyBillWave,
  faPencil,
  faTrashAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EDay } from 'types/EDay';
import { ELanguage } from 'types/ELanguage';
import { EOrderStatus } from 'types/EOrderStatus';
import { EPath } from 'types/EPath';
import { useAppContext } from 'global-state/context/useAppContext';
import { useOrderApi } from 'hooks/api/useOrderApi';
import { useCommentApi } from 'hooks/api/useCommentApi';
import { useDate } from 'hooks/useDate';
import { useLoadMapScript } from 'hooks/useLoadMapScript';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMarkerFactory } from 'hooks/useMarkerFactory';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Button } from 'components/basic/Button/Button';
import { Container } from 'components/basic/Container/Container';
import { Map } from 'components/basic/Map/Map';
import { NoData } from 'components/basic/NoData/NoData';
import { Tag } from 'components/basic/Tag/Tag';
import { showToast } from 'components/basic/Toast/Toast';
import { ToggleButton } from 'components/basic/ToggleButton/ToggleButton';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import destinationMarkerIconAR from 'assets/destination-marker-ar.png';
import destinationMarkerIconEN from 'assets/destination-marker-en.png';
import destinationMarkerIconFR from 'assets/destination-marker-fr.png';
import originMarkerIconAR from 'assets/origin-marker-ar.png';
import originMarkerIconEN from 'assets/origin-marker-en.png';
import originMarkerIconFR from 'assets/origin-marker-fr.png';
import 'components/pages/OrderDetails/OrderDetails.scss';

import { CommentForm } from 'components/pages/CommentForm/CommentForm';
import 'react-chat-elements/dist/main.css';

interface IOrderDetailsProps {
  type: 'client-mode' | 'driver-mode' | 'default';
}

export function OrderDetails({ type }: IOrderDetailsProps): ReactElement | null {
  const { t } = usePrefixedTranslation('OrderDetails');
  const { getItem } = useLocalStorage();
  const { generateMarkerWithCurrentPosition, generateMarker } = useMarkerFactory();
  const { isLoaded: isMapLoaded } = useLoadMapScript();
  const { reference } = useParams();
  const { getMyOrder, getOrderByReference, showPhone } = useOrderApi();
  const { showChatCommentsForOrder, showChatCommentsForDriver , showChatCommentsForClient} = useCommentApi();
  const { isBefore } = useDate();
  const { toggleNotifications, setActiveStatus, setArchiveStatus } = useOrderApi();
  const {
    state: {
      orderDetails,
      pendingApiCalls: { normal: isOrderLoading },
      orderComments,
      driverComments,
      clientComments
    }
  } = useAppContext();
  const language = getItem('language') || ELanguage.DEFAULT;
  const currentUser = getItem('phone') || null  ;
  const isExpired = orderDetails ? isBefore(orderDetails.deliveryDate, EDay.TOMORROW) : false;
  const isMyOrder = orderDetails?.clientInfo?.phone === currentUser ;
  const isArchive = orderDetails?.status === EOrderStatus.ARCHIVE;
  const [isPhoneVisible, setPhoneVisible] = useState<boolean>(false);

  const currentPositionMarker = generateMarkerWithCurrentPosition({ show: false });
  const originPositionMarker = generateMarker();
  const destinationPositionMarker = generateMarker();

  const archiveOrder = () => {
    setArchiveStatus(reference);
  };

  const activateOrder = () => {
    if (isExpired) {
      showToast.error(t('cannotActivate'));
      return;
    }

    setActiveStatus(reference);
  };

  const navigateToHome = () => {
    browserHistory.push(EPath.ROOT);
  };

  const navigateToOrderForm = () => {
    browserHistory.push(`${EPath.ORDER_FORM}/${reference}`);
  };

  const handleToggleNotifications = () => {
    if (isArchive) {
      showToast.error(t('archiveCannotActivateNotifications'));
      return;
    }

    toggleNotifications(reference);
  };

  const handleShowPhone = () => {
    setPhoneVisible(true);
    showPhone(orderDetails?.reference);
  }

  useEffect(() => {
    if (type === 'client-mode') {
      getMyOrder(reference);
      showChatCommentsForOrder(reference);
      if(clientComments.length === 0){
        showChatCommentsForClient();
      }
    }

    if (type === 'driver-mode') {
      getOrderByReference(reference);
      showChatCommentsForOrder(reference);

      if(driverComments.length === 0){
        showChatCommentsForDriver();
      }
    }

    if (type === 'default') {
      getOrderByReference(reference);
      showChatCommentsForOrder(reference);
    }
  }, []);

  useEffect(() => {
    if (orderDetails) {
      originPositionMarker.patch({
        position: {
          lat: orderDetails.originLat,
          lng: orderDetails.originLng
        }
      });
      destinationPositionMarker.patch({
        position: {
          lat: orderDetails.destinationLat,
          lng: orderDetails.destinationLng
        }
      });
    }
  }, [orderDetails]);

  useEffect(() => {
    if (language === ELanguage.EN) {
      originPositionMarker.patch({ icon: originMarkerIconEN });
      destinationPositionMarker.patch({ icon: destinationMarkerIconEN });
      return;
    }
    if (language === ELanguage.FR) {
      originPositionMarker.patch({ icon: originMarkerIconFR });
      destinationPositionMarker.patch({ icon: destinationMarkerIconFR });
      return;
    }
    if (language === ELanguage.AR) {
      originPositionMarker.patch({ icon: originMarkerIconAR });
      destinationPositionMarker.patch({ icon: destinationMarkerIconAR });
    }
  }, [language]);

  if (!isMapLoaded || isOrderLoading) {
    return null;
  }

  if (!orderDetails) {
    return (
      <Container fullWidth title={t('title')}>
        <NoData type='myOrderDetails' />
        <div className='flex justify-center mt-5'>
          <div className='w-64'>
            <Button onClick={navigateToHome} type='OUTLINE-PRIMARY'>
              <FontAwesomeIcon className='mr-2 rtl:ml-2' icon={faHome} />
              {t('home')}
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <>
      <Container fullWidth title={t('title')} >
        <div className='mt-16 h-96'>
          <Map
            isFitBounds
            type='show-only'
            center={currentPositionMarker.position}
            markers={[currentPositionMarker, originPositionMarker, destinationPositionMarker]}
          />
        </div>

        {(type === 'driver-mode' || type === 'default') && !isMyOrder &&
          <div className='client-info-wrapper'>
            <div className='client-info'>
              <div className='rounded-icon-wrapper'>
                {orderDetails.clientInfo?.profileImage ? (
                  <img className='profile-image' src={orderDetails.clientInfo?.profileImage} alt='Profile' />
                ) : (
                  <FontAwesomeIcon icon={faUser} />
                )}
              </div>

              <div className='flex-col'>
                <div className='full-name'>
                  <span>{orderDetails.clientInfo?.fullName}</span>
                </div>

                  {type === 'default' &&
                      <div className='phone'>
                          <span>{t('showPhoneMessage')}</span>
                      </div>
                  }

                  {type === 'driver-mode' &&
                      <div className='phone'>
                          { isPhoneVisible ? (
                          <a href={`tel:${orderDetails.clientInfo?.phone}`}>{orderDetails.clientInfo?.phone}</a>
                          ) : (
                          <div role='presentation' onClick={handleShowPhone}>{t('showPhone')}</div>
                          )}
                      </div>
                  }
              </div>
            </div>
          </div>
        }

        <div className="md:flex flex-wrap -mx-2">
          <div className='px-2 md:w-1/2' >
            <div className='order-details-wrapper'>
              {(isMyOrder || type === 'client-mode') && (
                  <div className='tags-section item-wrapper'>
                    {isExpired && <Tag type='DANGER' label={t('expired')} />}
                    {orderDetails.status === EOrderStatus.ACTIVE && <Tag type='SUCCESS' label={t('active')} />}
                    {orderDetails.status === EOrderStatus.ARCHIVE && <Tag type='WARNING' label={t('archive')} />}
                    {orderDetails.activeNotifications ? (
                        <Tag type='SUCCESS' label={t('notificationsEnabled')} />
                    ) : (
                        <Tag type='SECONDARY' label={t('notificationsDisabled')} />
                    )}
                  </div>
              )}

              <div className='item-wrapper'>
                <div className='item'>
                  <FontAwesomeIcon className='item-icon' icon={faHashtag} />
                  <span>
                          <span className='item-label'>{t('reference')} :</span>
                    {reference}
                        </span>
                </div>
              </div>

              <div className='item-wrapper'>
                <div className='item'>
                  <FontAwesomeIcon className='item-icon' icon={faLocationDot} />
                  <span>
                          <span className='item-label'>{t('origin')} :</span>
                    {orderDetails.origin}
                        </span>
                </div>
              </div>

              <div className='item-wrapper'>
                <div className='item'>
                  <FontAwesomeIcon className='item-icon' icon={faLocationDot} />
                  <span>
                          <span className='item-label'>{t('destination')} :</span>
                    {orderDetails.destination}
                        </span>
                </div>
              </div>

              <div className={`item-wrapper ${isExpired && 'expired'}`}>
                <div className='item'>
                  <FontAwesomeIcon className={`item-icon ${isExpired && 'expired'}`} icon={faCalendarDays} />
                  <span>
                          <span className='item-label'>{t('date')} :</span>
                    {orderDetails.deliveryDate}
                        </span>
                </div>
              </div>

              <div className='item-wrapper'>
                <div className='item'>
                  <FontAwesomeIcon className='item-icon' icon={faMoneyBillWave} />
                  <span>
                          <span className='item-label'>{t('price')} :</span>
                    {orderDetails.price ? t('orderPrice', { price: orderDetails.price }) : '-'}
                        </span>
                </div>
              </div>

              <div className='item-wrapper'>
                <div className='item'>
                  <FontAwesomeIcon className='item-icon' icon={faCircleInfo} />
                  <span>
                          <span className='item-label'>{t('description')} :</span>
                    {orderDetails.description || '-'}
                        </span>
                </div>
              </div>

              <div className='item-wrapper'>
                <div className='item'>
                  <FontAwesomeIcon className='item-icon' icon={faImage} />
                  <div className='item-label'>{t('image')} :</div>
                  {!orderDetails.image && '-'}
                </div>
              </div>

              <div>{orderDetails.image && <img className=' object-cover' src={orderDetails.image} alt='cargo' />}</div>
            </div>
          </div>
          <div className='px-2 md:w-1/2' >
            <div className='order-details-wrapper'>
                      <div className='rce-container-clist chat-list overflow-y-auto h-96'>
                        {orderComments.map((comment)=>{
                          return(
                            <div className="rce-container-citem pt-6">
                              <div className="rce-citem h-fit items-start">
                                <div className="rce-citem-avatar">
                                  <div className="rce-avatar-container circle large">
                                    <img alt="kursat_avatar" src={comment.senderInfo?.profileImage} className="rce-avatar"/>
                                  </div>
                                </div>
                                <div className="rce-citem-body">
                                  <div className="rce-citem-body--top">
                                    <div className="rce-citem-body--top-title capitalize font-bold	 ">{comment.senderInfo?.fullName}</div>
                                    <div className="rce-citem-body--top-time">{comment.creationDate}</div></div>
                                    <div className="rce-citem-body--bottom">
                                      <div className="rce-citem-body--bottom-title whitespace-pre-line">{comment.contenuText}</div>
                                      <div className="rce-citem-body--bottom-tools"/>
                                      <div className="rce-citem-body--bottom-tools-item-hidden-hover"/>
                                      <div className="rce-citem-body--bottom-status"/>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          );
                        })}
                      </div>

                      {orderDetails.status === EOrderStatus.ACTIVE && <CommentForm disabled={type === 'default' && !isMyOrder}/>}
            </div>
          </div>
        </div>
 </Container>
      {((isMyOrder && type !== 'driver-mode' ) || type === 'client-mode') &&
        <div dir='ltr' className='fixed-footer-actions'>
          {orderDetails.status === EOrderStatus.ACTIVE && (
            <Button className='button-action' type='OUTLINE-DANGER' onClick={archiveOrder}>
              <FontAwesomeIcon icon={faTrashAlt} />
              <span>{t('archiveAction')}</span>
            </Button>
          )}
          {orderDetails.status === EOrderStatus.ARCHIVE && (
            <Button className='button-action' type='OUTLINE-PRIMARY' onClick={activateOrder}>
              <FontAwesomeIcon icon={faCheck} />
              <span>{t('activateAction')}</span>
            </Button>
          )}
          <Button className='button-action' type='OUTLINE-PRIMARY' onClick={navigateToOrderForm}>
            <FontAwesomeIcon icon={faPencil} />
            <span>{t('editAction')}</span>
          </Button>
          <div className='toggle-wrapper'>
            <ToggleButton
              checked={orderDetails.activeNotifications}
              onChange={handleToggleNotifications}
              checkedIcon={<FontAwesomeIcon icon={faBell} />}
              uncheckedIcon={<FontAwesomeIcon icon={faBellSlash} />}
            />
          </div>
        </div>
      }
    </>
  );
}
