import { TFormControlValue } from 'types/TFormControlValue';
import { TDriverLicenseFormControlName } from 'types/form-control-names/TDriverLicenseFormControlName';

export interface IDriverLicenseDto {
  licenseNumber: string;
  imageRecto: string;
  imageVerso: string;
  expirationDate: string;
}

export const mapToDriverLicenseForm = (
  driverLicenseDto?: IDriverLicenseDto
): Record<TDriverLicenseFormControlName, TFormControlValue> => {
  return {
    licenseNumber: driverLicenseDto?.licenseNumber,
    imageRecto: driverLicenseDto?.imageRecto,
    imageVerso: driverLicenseDto?.imageVerso,
    expirationDate: driverLicenseDto?.expirationDate
  };
};
