import { format, isBefore } from 'date-fns';
import { dayToDateMapper, EDay } from 'types/EDay';

const DATE_FORMAT = 'yyyy-MM-dd';

export const useDate = () => ({
  isBefore: (value: string, day: EDay): boolean => {
    const dateValue = new Date(value);
    dateValue.setHours(0, 0, 0, 0);

    const dayToCompare = dayToDateMapper[day];
    dayToCompare.setHours(0, 0, 0, 0);

    return isBefore(dateValue, dayToCompare);
  },
  format: (value: Date | undefined | null): string => {
    if (!value) {
      return '';
    }

    return format(value, DATE_FORMAT);
  },
  extractDateFromRange: (rangeDate: string, startOrEnd: 'start' | 'end'): Date | undefined => {
    const [startDate, endDate] = rangeDate.split('|');
    if(startOrEnd === 'start') {
      return startDate ? new Date(startDate) : undefined;
    }
    return endDate ? new Date(endDate) : undefined;
  },
  extractDateAsStringFromRange: (rangeDate: string, startOrEnd: 'start' | 'end'): string => {
    const [startDate, endDate] = rangeDate.split('|');
    if(startOrEnd === 'start') {
      return startDate;
    }
    return endDate;
  }
});
