import React, { useRef } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalTranslation } from 'hooks/useGlobalTranslation';
import { IInputProps } from 'components/basic/Input/InputProps';

export function InputImage<IFormControlName extends string>({
  t,
  formControl: { label, value, error, dirty, required, disabled, maxFileSize, handleChange }
}: IInputProps<IFormControlName>): React.ReactElement {
  const { tGlobal } = useGlobalTranslation();
  const hiddenImageInput = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    hiddenImageInput.current?.click();
  };

  return (
    <div className='input-wrapper'>
      <input
        type='file'
        className='hidden'
        disabled={disabled}
        style={{ display: 'none' }}
        accept='image/*'
        data-testid='hidden-input'
        onChange={handleChange}
        ref={hiddenImageInput}
      />

      <button
        disabled={disabled}
        type='button'
        onClick={handleUpload}
        className={`input image ${error && dirty ? 'invalid' : 'valid'}`}
      >
        {value ? (
          <img className='uploaded-image' src={value} alt='uploaded' />
        ) : (
          <>
            <FontAwesomeIcon icon={faPlus} />
            <span className='mx-1'>{tGlobal('upload')}</span>
          </>
        )}
      </button>

      <p className={`label stick-float ${error && dirty ? 'invalid' : 'valid'}`}>
        {t(label)}
        {required ? '*' : ''}
      </p>

      {error && dirty && <p className='error-message'>{tGlobal(error, { maxFileSize })}</p>}
    </div>
  );
}
