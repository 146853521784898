import imageCompression from 'browser-image-compression';
import { ChangeEvent } from 'react';
import { ImageCompressionOptions } from 'configurations/ImageCompressionOptions';

export const useFile = () => {
  const isValidBase64Size = (value: string, maxSize: number) => {
    if (!value) {
      return false;
    }
    const fileSize = window.atob(value.split(',')[1])?.length;
    return fileSize < maxSize * 1024 ** 2;
  };

  const compressImage = async (file: File) => {
    const compressedFile = await imageCompression(file, ImageCompressionOptions);
    return compressedFile;
  };
  const convertToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const getFileContent = async (event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const compressedFile = await compressImage(file);
      const value = await convertToBase64(compressedFile);
      return value;
    }

    return '';
  };

  return {
    convertToBase64,
    getFileContent,
    isValidBase64Size,
    compressImage
  };
};
