
import { animateScroll as scroll } from 'react-scroll';
import React from 'react';
import { EPath } from 'types/EPath';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { toggleIsChatOpen } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useCommentApi } from 'hooks/api/useCommentApi';
import { Drawer } from 'components/basic/Drawer/Drawer';
import 'components/layout/SideMenu/SideMenu.scss';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { useNavigate } from "react-router-dom";


export function ClientChatMenu(): React.ReactElement {
  const { t } = usePrefixedTranslation('ChatMenu');
  const { showChatCommentsForClient, showChatCommentsForOrder} = useCommentApi();
  const navigate = useNavigate();
  const {
    state: {
      isChatOpen,
      clientComments,
    },
    dispatch
  } = useAppContext();

  const toggleMenu = () => {
    showChatCommentsForClient();
    dispatch(toggleIsChatOpen());
  };

  const navigateToDetails = (reference: string) => () => {
    toggleMenu();
    scroll.scrollToTop();
    showChatCommentsForOrder(reference);
    browserHistory.push(`${EPath.MY_ORDER_DETAILS}/${reference}`);
    navigate(0);
  };

  console.log(clientComments.length );

  return (
          <Drawer title={t('title')} isOpen={isChatOpen} toggle={toggleMenu}>
            <div className='side-menu'>
              <div className='rce-container-clist chat-list  overflow-y-auto h-screen'>
                {clientComments.map((comment)=>{
                  return(
                      <div className="rce-container-citem" onClick={navigateToDetails(comment.reference)} onKeyDown={navigateToDetails(comment.reference)} role="button" tabIndex={0} >
                        <div className="rce-citem">
                          <div className="rce-citem-avatar">
                            <div className="rce-avatar-container circle large">
                              <img alt="kursat_avatar" src={comment.senderInfo?.profileImage} className="rce-avatar"/>
                            </div>
                          </div>
                          <div className="rce-citem-body">
                            <div className="rce-citem-body--top">
                              <div className="rce-citem-body--top-title">{comment.senderInfo?.fullName}</div>
                              <div className="rce-citem-body--top-time">{comment.creationDate}</div></div>
                            <div className="rce-citem-body--bottom">
                              <div className="rce-citem-body--bottom-title">{comment.contenuText}</div>
                              <div className="rce-citem-body--bottom-tools"/>
                              <div className="rce-citem-body--bottom-tools-item-hidden-hover"/>
                              <div className="rce-citem-body--bottom-status"/>
                            </div>
                          </div>
                        </div>
                      </div>

                  );
                })}
                {clientComments.length === 0 && (
                    <span>{t('nodata')}</span>
                )
              }
              </div>
            </div>
      </Drawer>
  );
}
