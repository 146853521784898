import { TFormControlValue } from 'types/TFormControlValue';
import { TVehicleFormControlName } from 'types/form-control-names/TVehicleFormControlName';

export interface IVehicleDto {
  brand: string;
  plateNumber: string;
  image: string;
  grayCardImageRecto: string;
  grayCardImageVerso: string;
}

export const mapToVehicleForm = (vehicleDto?: IVehicleDto): Record<TVehicleFormControlName, TFormControlValue> => {
  return {
    brand: vehicleDto?.brand,
    plateNumber: vehicleDto?.plateNumber,
    image: vehicleDto?.image,
    grayCardImageRecto: vehicleDto?.grayCardImageRecto,
    grayCardImageVerso: vehicleDto?.grayCardImageVerso
  };
};
