import Dropdown, { Option } from 'react-dropdown';
import React, { useEffect } from 'react';
import { ELanguage } from 'types/ELanguage';
import { i18n } from 'configurations/i18nConfig';
import { useDocumentDir } from 'hooks/useDocumentDir';
import { useLocalStorage } from 'hooks/useLocalStorage';

export function LanguageDropdown(): React.ReactElement {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lang = urlParams.get('lang');
  const { setDirection } = useDocumentDir();
  const { getItem, setItem } = useLocalStorage();
  if(lang !== null){
    setItem('language',lang);
    i18n.changeLanguage(lang);
    setDirection(lang);
  }
  const defaultLanguage = getItem('language') || (navigator.language).substr(0, 2) || ELanguage.DEFAULT;
  const options = [
    { value: ELanguage.EN, label: 'En' },
    { value: ELanguage.FR, label: 'Fr' },
    { value: ELanguage.AR, label: 'AR' }
  ];

  useEffect(() => {
    setDirection(defaultLanguage);
    i18n.changeLanguage(defaultLanguage);
  }, []);

  const handleChangeLanguage = (option: Option) => {
    const language = option.value;

    setItem('language', language);
    i18n.changeLanguage(language);
    setDirection(language);
  };

  return <Dropdown  className='lang-icon' options={options} value={defaultLanguage} onChange={handleChangeLanguage} />;
}
