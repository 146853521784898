import { useParams } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';
import {
  faCalendarDays,
  faCircleInfo,
  faHashtag,
  faHome,
  faLocationDot,
  faMoneyBillWave
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EDay } from 'types/EDay';
import { ELanguage } from 'types/ELanguage';
import { EPath } from 'types/EPath';
import { mapToOrderForm } from 'types/dtos/IOrderDto';
import { useAppContext } from 'global-state/context/useAppContext';
import { useOrderApi } from 'hooks/api/useOrderApi';
import { useFormControl } from 'hooks/useFormControl';
import { useFormGroup } from 'hooks/useFormGroup';
import { useLoadMapScript } from 'hooks/useLoadMapScript';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMarkerFactory } from 'hooks/useMarkerFactory';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { IFormGroup } from 'forms/group/IFormGroup';
import { Button } from 'components/basic/Button/Button';
import { Container } from 'components/basic/Container/Container';
import { Form } from 'components/basic/Form/Form';
import { Input } from 'components/basic/Input/Input';
import { Map } from 'components/basic/Map/Map';
import { NoData } from 'components/basic/NoData/NoData';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { TOrderFormControlName } from 'types/form-control-names/TOrderFormControlName';
import destinationMarkerIconAR from 'assets/destination-marker-ar.png';
import destinationMarkerIconEN from 'assets/destination-marker-en.png';
import destinationMarkerIconFR from 'assets/destination-marker-fr.png';
import originMarkerIconAR from 'assets/origin-marker-ar.png';
import originMarkerIconEN from 'assets/origin-marker-en.png';
import originMarkerIconFR from 'assets/origin-marker-fr.png';

export function OrderForm(): ReactElement | null {
  const { getItem } = useLocalStorage();
  const { t } = usePrefixedTranslation('OrderForm');
  const { isLoaded: isMapLoaded } = useLoadMapScript();
  const {
    state: {
      orderDetails,
      pendingApiCalls: { normal: isOrderLoading }
    }
  } = useAppContext();
  const { getMyOrder, saveOrder, updateOrder } = useOrderApi();
  const { generateMarkerWithCurrentPosition } = useMarkerFactory();
  const { reference } = useParams();
  const isEditMode = !!reference;
  const currentPositionMarker = generateMarkerWithCurrentPosition({ show: false });
  const language = getItem('language') || ELanguage.DEFAULT;

  const referenceInput = useFormControl({
    name: 'reference',
    defaultValue: reference,
    disabled: true,
    icon: faHashtag
  });

  const orderForm = useFormGroup<TOrderFormControlName>({
    formControlSpecs: [
      {
        name: 'origin',
        type: 'map-position-picker',
        partialMarker: { show: false },
        required: true,
        maxLength: 200,
        autoCompleteDisabled: true,
        icon: faLocationDot
      },
      {
        name: 'destination',
        type: 'map-position-picker',
        partialMarker: { show: false },
        required: true,
        maxLength: 200,
        autoCompleteDisabled: true,
        icon: faLocationDot
      },
      {
        name: 'deliveryDate',
        type: 'date',
        required: true,
        minDate: EDay.TOMORROW,
        icon: faCalendarDays
      },
      {
        name: 'price',
        type: 'number',
        minValue: 0,
        required: true,
        icon: faMoneyBillWave
      },
      {
        name: 'description',
        type: 'text-area',
        maxLength: 200,
        icon: faCircleInfo
      },
      {
        name: 'image',
        type: 'image',
        maxFileSize: '2MB'
      }
    ],
    onSubmit: (formGroup: IFormGroup<TOrderFormControlName>) => {
      if (isEditMode) {
        updateOrder(reference, formGroup);
      } else {
        saveOrder(formGroup);
      }
    }
  });

  const navigateToHome = () => {
    browserHistory.push(EPath.ROOT);
  };

  useEffect(() => {
    if (isEditMode) {
      getMyOrder(reference);
    }
  }, []);

  useEffect(() => {
    if (isEditMode && orderDetails) {
      orderForm.updateFormValues(mapToOrderForm(orderDetails));
      orderForm.patchMarker('origin', {
        show: true,
        position: {
          lat: orderDetails.originLat,
          lng: orderDetails.originLng
        }
      });
      orderForm.patchMarker('destination', {
        show: true,
        position: {
          lat: orderDetails.destinationLat,
          lng: orderDetails.destinationLng
        }
      });
    }
  }, [orderDetails]);

  useEffect(() => {
    if (language === ELanguage.EN) {
      orderForm.patchMarker('origin', { icon: originMarkerIconEN });
      orderForm.patchMarker('destination', { icon: destinationMarkerIconEN });
      return;
    }
    if (language === ELanguage.FR) {
      orderForm.patchMarker('origin', { icon: originMarkerIconFR });
      orderForm.patchMarker('destination', { icon: destinationMarkerIconFR });
      return;
    }
    if (language === ELanguage.AR) {
      orderForm.patchMarker('origin', { icon: originMarkerIconAR });
      orderForm.patchMarker('destination', { icon: destinationMarkerIconAR });
    }
  }, [language]);

  if (!isMapLoaded || isOrderLoading) {
    return null;
  }

  if (isEditMode && !orderDetails) {
    return (
      <Container fullWidth title={t('titleEditMode')}>
        <NoData type='myOrderDetails' />
        <div className='flex justify-center mt-5'>
          <div className='w-64'>
            <Button onClick={navigateToHome} type='OUTLINE-PRIMARY'>
              <FontAwesomeIcon className='mr-2 rtl:ml-2' icon={faHome} />
              {t('home')}
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container fullWidth title={t(isEditMode ? 'titleEditMode' : 'titleCreateMode')}>
      <div className='mt-16 h-96'>
        <Map
          isFitBounds
          type='show-only'
          center={currentPositionMarker.position}
          markers={[currentPositionMarker, orderForm.getMarker('origin'), orderForm.getMarker('destination')]}
        />
      </div>

      <Container>
        {isEditMode && <Input t={t} formControl={referenceInput} />}
        <Form t={t} formGroup={orderForm} />
      </Container>
    </Container>
  );
}
