import { ELanguage } from 'types/ELanguage';

enum EDirection {
  RTL = 'rtl',
  LTR = 'ltr'
}

export const useDocumentDir = () => ({
  setDirection: (language: string) => {
    if (language === ELanguage.AR) {
      document.documentElement.setAttribute('dir', EDirection.RTL);
      return;
    }
    document.documentElement.setAttribute('dir', EDirection.LTR);
  }
});
