import React from 'react';

import {EClientStatus, EConnectedAs, EDriverStatus} from 'types/dtos/IUserDto';
import { toggleIsMenuOpen, toggleIsChatOpen } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { LanguageDropdown } from 'components/layout/Header/LanguageDropdown/LanguageDropdown';
import { ProfileButton } from 'components/layout/Header/ProfileButton/ProfileButton';
import { ChatButton} from 'components/layout/Header/ChatButton/ChatButton';
import 'components/layout/Header/Header.scss';
import { EPath } from 'types/EPath';
import { useNavigate } from "react-router-dom";
import { useSwitchToDriverModeApi } from 'hooks/api/useSwitchToDriverModeApi';
import { useUuid } from 'hooks/useUuid';
import { Icon } from 'components/basic/Icon/Icon';
import { faTruck, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { showToast } from 'components/basic/Toast/Toast';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import {animateScroll as scroll} from "react-scroll/modules";

export function Header(): React.ReactElement {
  const { getItem } = useLocalStorage();
  const {
    state: { connectedUser, },
    dispatch
  } = useAppContext();
  const { t } = usePrefixedTranslation('SideMenu');
  const isConnected = !!getItem('token');
  const isActiveClient = connectedUser?.clientStatus === EClientStatus.ACTIVE;
  const isActiveDriver = connectedUser?.driverStatus === EDriverStatus.ACTIVE;
  const isConnectedAsClient = connectedUser?.connectedAs === EConnectedAs.CLIENT;
  const isConnectedAsDriver = connectedUser?.connectedAs === EConnectedAs.DRIVER;
  const navigate = useNavigate();
  const { setConnectedAs } = useSwitchToDriverModeApi();
  const { generate } = useUuid();
  const id = generate();
  const setIsMenuOpen = () => {
    dispatch(toggleIsMenuOpen());
  };
  const setIsChatOpen = () => {
    dispatch(toggleIsChatOpen());
  };

  const navigateToSignUp = () => {
    scroll.scrollToTop();
    navigate(EPath.SIGN_UP);
  };

  const navigateToDriverMode = () => {
    if (!isActiveDriver) {
      navigate(EPath.DRIVER_MODE_FORM);
      return;
    }
    setConnectedAs(EConnectedAs.DRIVER);
  };

  const navigateToClientMode = () => {
    setConnectedAs(EConnectedAs.CLIENT);
  };
  const handleDriverModeClick = () => {
    if (!isActiveClient) {
      showToast.warn(t('cannotPassDriver'));
      navigateToSignUp();
    } else {
      navigateToDriverMode();
    }
  };

  return (
    <div className='header' dir='ltr'>
      {isConnectedAsClient && (
            <div className='toggle-wrapper'>
              <label htmlFor={id} dir='ltr' className='radio-group-wrapper'>
                <input onChange={handleDriverModeClick} id={id} type='checkbox' className='hidden peer' />
                <span className='radio-element rounded-l-3xl bg-primary-500 text-white'>
              <Icon icon={faUserTie} position='center'   wrapperClassName='inline-flex' />
      </span>
                <span className='radio-element rounded-r-3xl bg-white text-gray-600 '>
              <Icon icon={faTruck} position='center'  wrapperClassName='inline-flex' />
      </span>
              </label>
            </div>

      )}
      {isConnectedAsDriver && (
            <div className='toggle-wrapper'>
              <label htmlFor={id} dir='ltr' className='radio-group-wrapper'>
                <input onChange={navigateToClientMode} id={id} type='checkbox' className='hidden peer' />
                <span className='radio-element rounded-l-3xl bg-white text-gray-600 '>
              <Icon icon={faUserTie} position='center'  wrapperClassName='inline-flex' />
               </span>
                <span className='radio-element rounded-r-3xl bg-primary-500 text-white '>
              <Icon icon={faTruck} position='center'   wrapperClassName='inline-flex' />
                </span>
              </label>
            </div>

      )}


      {isConnected && <ChatButton setIsOpen={setIsChatOpen}/>}
      <LanguageDropdown />
      {isConnected && <ProfileButton setIsOpen={setIsMenuOpen} profileImage={connectedUser?.profileImage} />}

    </div>
  );
}
