import React, { MouseEvent } from 'react';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EDay } from 'types/EDay';
import { EOrderStatus } from 'types/EOrderStatus';
import { IOrderDto } from 'types/dtos/IOrderDto';
import { useDate } from 'hooks/useDate';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Tag } from 'components/basic/Tag/Tag';
import cargoAlt from 'assets/cargo.jpg';
import 'components/pages/ClientHome/OrderCard/OrderCard.scss';

interface IOrderCardProps {
  order: IOrderDto;
  type: 'client-mode' | 'driver-mode' |'default';
  onClick?: (event: MouseEvent) => void;
}

export function OrdersCard({
  order: {
    reference,
    originCity,
    destinationCity,
    deliveryDate,
    price,
    status,
    allOffers = [],
    newOffers = [],
    image,
    activeNotifications,
    description,
    clientInfo
  },
  type,
  onClick
}: IOrderCardProps): React.ReactElement {
  const firstname = clientInfo?.fullName?.split(' ')[0];
  const lastname = clientInfo?.fullName?.split(' ').pop()?.charAt(0).toUpperCase();

  const { t } = usePrefixedTranslation('OrdersCard');
  const { isBefore } = useDate();
  const isExpired = isBefore(deliveryDate, EDay.TOMORROW);

  return (
    <div role='presentation' className='card-container h-72' dir='ltr' onClick={onClick}>
      {newOffers.length !== 0 && activeNotifications && (
        <span className='new-offers-badge-wrapper'>
          <span className='new-offers-badge'>{newOffers.length}</span>
        </span>
      )}

      <div className='card-header'>
        <span className='hidden'>#{reference}</span>
        <span style={{ textTransform: 'capitalize' }} ># {firstname} {lastname}.</span>

        {type === 'client-mode' && (
          <span className='tags-wrapper'>
            {!activeNotifications && <Tag type='SECONDARY' label={t('notificationsDisabled')} />}
          </span>
        )}
         <span className='tags-wrapper'>
            {isExpired && <Tag type='DANGER' label={t('expired')} />}
            {(!isExpired && status === EOrderStatus.ACTIVE) && <Tag type='SUCCESS' label={t('active')} />}
            {status === EOrderStatus.ARCHIVE && <Tag type='WARNING' label={t('archive')} />}
         </span>

      </div>

      {description && (
          <div className="rce-citem-body--bottom-title pb-8">{description}</div>
      )}
      <span className='separator mb-5' />

      <div className='image-locations'>
        <div className='image-wrapper'>
          {image ? (
            <img className='image' src={image} alt='cargo' />
          ) : (
            <img className='image' src={cargoAlt} alt='cargoAlt' />
          )}
        </div>

        <div className='locations'>
          <div className='flex items-center'>
            <FontAwesomeIcon className='icon' icon={faLocationDot} />
            <span className='mx-1 ellipsis'>{originCity}</span>
          </div>

          <div className='locations-line-wrapper'>
            <span className='locations-line' />
          </div>

          <div className='flex items-center'>
            <FontAwesomeIcon className='icon' icon={faLocationDot} />
            <span className='mx-1 ellipsis'>{destinationCity}</span>
          </div>
        </div>
      </div>

      <span className='separator mt-5 mb-3' />

      <div className='info-wrapper-parent'>
        <div className='info-wrapper'>
          <div className='info-title'>{t('offers')}</div>
          <div className='info'>{allOffers.length}</div>
        </div>
        <div className='info-wrapper'>
          <div className='info-title'>{t('date')}</div>
          <div className={`info ${isExpired && 'expired'}`}>{deliveryDate}</div>
        </div>
        <div className='info-wrapper'>
          <div className='info-title'>{t('price')}</div>
          <div className='info orderPrice'>{price ? t('orderPrice', { price }) : '-'}</div>
        </div>
      </div>
    </div>
  );
}
