import React from 'react';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/basic/Button/Button';

interface IChatButtonProps {
  setIsOpen: () => void;
  profileImage?: string;
}

export function ChatButton({ setIsOpen, profileImage }: IChatButtonProps): React.ReactElement {
  return (
    <Button type='ROUNDED' onClick={setIsOpen}>
      {profileImage ? (
        <img className='profile-image' src={profileImage} alt='Profile'/>
      ) : (
        <FontAwesomeIcon icon={faComment} />
      )}
    </Button>
  );
}
