import { IDispatcher } from 'types/IDispatcher';
import { defaultPosition, IPosition } from 'types/IPosition';
import { TDispatch } from 'types/TDispatch';
import { TPartialDispatch } from 'types/TPartialDispatch';
import defaultMarkerIcon from 'assets/default-marker.png';

export interface IMarker extends IDispatcher<IMarker> {
  id: string;
  position: IPosition;
  address: string;
  show: boolean;
  icon: string;
  circle: {
    show: boolean;
    color: string;
    radiusInMeters: number;
  };
  dispatch: TDispatch<IMarker>;
  patch: TPartialDispatch<IMarker>;
}

export const defaultMarker: IMarker = {
  id: '',
  position: defaultPosition,
  address: '',
  show: true,
  icon: defaultMarkerIcon,
  circle: {
    show: false,
    color: '#ff0000',
    radiusInMeters: 0
  },
  dispatch: () => {},
  patch: () => {}
};
