import { defaultMarker, IMarker } from 'types/IMarker';
import { useStateWithDispatchers } from 'hooks/useStateWithDispatchers';
import { useUuid } from 'hooks/useUuid';

export const useMarkerFactory = () => {
  const { generate } = useUuid();

  return ({
    generateStatelessMarker: (partialMarker?: Partial<IMarker>): IMarker => {
      return ({ ...defaultMarker, ...partialMarker, id: generate() });
    },
    generateMarker: (partialMarker?: Partial<IMarker>): IMarker => {
      return useStateWithDispatchers<IMarker>({ ...defaultMarker, ...partialMarker, id: generate() });
    },
    generateMarkerWithCurrentPosition: (partialMarker: Partial<IMarker>): IMarker => {
      const marker = { ...defaultMarker, ...partialMarker, id: generate() };

      let { lat, lng } = marker.position;
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          lat = coords.latitude;
          lng = coords.longitude;
          marker.patch({ ...marker, position: { lat, lng } });
        },
        () => {
          marker.patch({ ...marker, show: false });
        }
      );

      return useStateWithDispatchers<IMarker>(marker);
    }
  });
};
