import { TFormControlValue } from 'types/TFormControlValue';
import { TOrdersFilterFormControlName } from 'types/form-control-names/TOrdersFilterFormControlName';

export interface IOrdersFilterDto {
  origin: string;
  originRadius: number;
  originLat: number;
  originLng: number;
  destination?: string;
  destinationRadius?: number;
  destinationLat?: number;
  destinationLng?: number;
  startDeliveryDate: string;
  endDeliveryDate?: string;
}

export const mapToOrdersFilterForm = (
  ordersFilterDto?: IOrdersFilterDto
): Record<TOrdersFilterFormControlName, TFormControlValue> => {
  return {
    origin: ordersFilterDto?.origin,
    originRadius: ordersFilterDto?.originRadius,
    destination: ordersFilterDto?.destination,
    destinationRadius: ordersFilterDto?.destinationRadius,
    deliveryDate: `${ordersFilterDto?.startDeliveryDate}|${ordersFilterDto?.endDeliveryDate || ''}`
  };
};
