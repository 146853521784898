import { animateScroll as scroll } from 'react-scroll/modules';
import { ELanguage } from 'types/ELanguage';
import { EHttpMethod } from 'types/http/EHttpMethod';
import { IRequest } from 'types/http/IRequest';
import { IResponseBody, SERVER_ERROR_RESPONSE_BODY } from 'types/http/IResponseBody';
import {
  endCriticalApiCall,
  endNormalApiCall,
  startCriticalApiCall,
  startNormalApiCall
} from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useObject } from 'hooks/useObject';
import { showToast } from 'components/basic/Toast/Toast';

interface IOptions {
  critical?: boolean;
  scrollToTopDisabled?: boolean;
}

interface IPerformArgs<TParams, TBody, TResponse> {
  request: IRequest<TParams, TBody>;
  onSuccess?: (value: IResponseBody<TResponse>) => void;
  options?: IOptions;
}

interface IGetArgs<TParams, TResponse> {
  url: string;
  params?: TParams;
  onSuccess?: (value: IResponseBody<TResponse>) => void;
  options?: IOptions;
}

interface IPostArgs<TParams, TBody, TResponse> {
  url: string;
  params?: TParams;
  body?: TBody;
  onSuccess?: (value: IResponseBody<TResponse>) => void;
  options?: IOptions;
}

interface IFetchRequest {
  method: EHttpMethod;
  headers: {};
  body?: string;
}

const { getItem } = useLocalStorage();

const isOk = (response: Response) => response.ok;

const isParsable = (jsonAsString: string) => {
  try {
    JSON.parse(jsonAsString);
  } catch (e) {
    return false;
  }
  return true;
};

const getText = (response: Response) => response.text();

const getJson = (response: string) => JSON.parse(response);

const appendParams = (url: string, params?:{})=> {
  const { isEmpty } = useObject();

  if (isEmpty(params)) {
    return url;
  }

  return `${url}?${new URLSearchParams(params)}`;
};

const prepareFetchRequest = <TBody>(method: EHttpMethod, body?: TBody): IFetchRequest => {
  let headers: {} = {
    'ngrok-skip-browser-warning': 'true',
    'Content-Type': 'application/json',
    'Accept-Language': getItem('language') || ELanguage.DEFAULT
  };

  const token = getItem('token');
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  const request: IFetchRequest = {
    method,
    headers
  };

  if (method === EHttpMethod.POST && body) {
    request.body = JSON.stringify(body);
  }

  return request;
};

const fetchApi = <TParams, TBody>(request: IRequest<TParams, TBody>): Promise<Response> => {
  const { method, url, params, body } = request;

  const urlWithParams = appendParams(url, params);
  const fetchRequest = prepareFetchRequest(method, body);
  return fetch(urlWithParams, fetchRequest);
};

const perform = async <TParams, TBody, TResponse>(
  request: IRequest<TParams, TBody>
): Promise<IResponseBody<TResponse>> => {
  try {
    return await fetchApi<TParams, TBody>(request)
      .then((response) => {
        if (!isOk(response)) {
          throw new Error();
        }
        return getText(response);
      })
      .then((response) => {
        if (!isParsable(response)) {
          throw new Error();
        }
        return getJson(response);
      });
  } catch (e) {
    return SERVER_ERROR_RESPONSE_BODY;
  }
};

export const useHttp = () => {
  const { dispatch } = useAppContext();

  const performRequest = async <TParams, TBody, TResponse>({
    request,
    onSuccess,
    options,
  }: IPerformArgs<TParams, TBody, TResponse>): Promise<void> => {
    const startApiCall = options?.critical ? startCriticalApiCall : startNormalApiCall;
    const endApiCall = options?.critical ? endCriticalApiCall : endNormalApiCall;

    dispatch(startApiCall());
    const response = await perform<TParams, TBody, TResponse>(request);
    dispatch(endApiCall());

    if(!options?.scrollToTopDisabled) {
      scroll.scrollToTop();
    }

    if (!response.success) {
      showToast.error(response.message);
      return;
    }

    onSuccess?.(response);
  };

  return {
    get: async <TParams, TResponse>(args: IGetArgs<TParams, TResponse>): Promise<void> => {
      await performRequest({
        request: {
          method: EHttpMethod.GET,
          url: args.url,
          params: args.params
        },
        onSuccess: args.onSuccess,
        options: args.options
      });
    },
    post: async <TParams, TBody, TResponse>(args: IPostArgs<TParams, TBody, TResponse>): Promise<void> => {
      await performRequest({
        request: {
          method: EHttpMethod.POST,
          url: args.url,
          params: args.params,
          body: args.body
        },
        onSuccess: args.onSuccess,
        options: args.options
      });
    }
  };
};