import { TFunction } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll/modules';
import React from 'react';
import { IPosition } from 'types/IPosition';
import { useMap } from 'hooks/useMap';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { IFormControl } from 'forms/control/IFormControl';
import { Button } from 'components/basic/Button/Button';
import { Drawer } from 'components/basic/Drawer/Drawer';
import { InputMapAutoComplete } from 'components/basic/Input/InputMapAutoComplete/InputMapAutoComplete';
import { Map } from 'components/basic/Map/Map';
import 'components/basic/MapPositionPicker/MapPositionPicker.scss';

interface IMapPositionPickerProps<IFormControlName extends string> {
  formControl: IFormControl<IFormControlName>;
  t: TFunction<'translation'>;
}

export function MapPositionPicker<IFormControlName extends string>({
  t,
  formControl
}: IMapPositionPickerProps<IFormControlName>): React.ReactElement {
  const { label, isOpenMapPositionPicker, toggleOpenMapPositionPicker, marker, patchMarker } = formControl;
  const { getAddress } = useMap();

  const handleToggle = () => {
    toggleOpenMapPositionPicker();
    scroll.scrollToTop();
  };

  const updateMarker = (position: IPosition): void => {
    patchMarker({ position, show: true });
    getAddress(position).then((address) => {
      patchMarker({ address });
    });
  };

  useUpdateEffect(() => {
    if (isOpenMapPositionPicker) {
      updateMarker(marker.position);
    }
  }, [isOpenMapPositionPicker]);

  return (
    <Drawer type='bottom' fullScreen title={t(label)} hideCloseButton isOpen={!!isOpenMapPositionPicker} toggle={handleToggle}>
      <div className='input-map-wrapper'>
        <InputMapAutoComplete className='input-map' t={t} formControl={formControl} />
        <Button className='confirm-button' disabled={!!formControl.error} onClick={handleToggle}>
          {t('confirmPosition')}
        </Button>
      </div>

      <div className='map-wrapper'>
        <Map type='picker' center={marker.position} setCenter={updateMarker} markers={[marker]} />
      </div>
    </Drawer>
  );
}
