import React from 'react';
import { useAuthenticationApi } from 'hooks/api/useAuthenticationApi';
import { useEnvironment } from 'hooks/useEnvironment';
import { useFormGroup } from 'hooks/useFormGroup';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { Container } from 'components/basic/Container/Container';
import { Form } from 'components/basic/Form/Form';

type TFormControlName = 'verificationCode';

export function VerificationCode(): React.ReactElement {
  const { get } = useEnvironment();
  const { t } = usePrefixedTranslation('VerificationCode');
  const { checkVerificationCode } = useAuthenticationApi();
  const defaultPin = get('PREDEFINED_VERIFICATION_CODE');

  const verificationCodeForm = useFormGroup<TFormControlName>({
    formControlSpecs: [
      {
        name: 'verificationCode',
        type: 'pin',
        defaultValue: defaultPin,
        exactLength: 4
      }
    ],
    onSubmit: checkVerificationCode
  });

  useUpdateEffect(() => {
    if (verificationCodeForm.isValid()) {
      verificationCodeForm.handleSubmit();
    }
  }, [verificationCodeForm.isValid()]);

  return (
    <Container title={t('title')} boxed>
      <Form t={t} formGroup={verificationCodeForm} />
    </Container>
  );
}
