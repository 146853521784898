import { ar, enUS, fr } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import React, { useEffect } from 'react';
import { dayToDateMapper } from 'types/EDay';
import { ELanguage } from 'types/ELanguage';
import { useDate } from 'hooks/useDate';
import { useGlobalTranslation } from 'hooks/useGlobalTranslation';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Icon } from 'components/basic/Icon/Icon';
import { IInputProps } from 'components/basic/Input/InputProps';

export function InputDateRange<IFormControlName extends string>({
    t,
    formControl: {
      name,
      label,
      value,
      error,
      dirty,
      minDate,
      required,
      disabled,
      icon,
      updateValue
    }
  }: IInputProps<IFormControlName>): React.ReactElement {
  const { getItem } = useLocalStorage();
  const { tGlobal } = useGlobalTranslation();
  const { format, extractDateFromRange } = useDate();

  const labelWithAsterisk = t(label) + (required ? '*' : '');
  const language = getItem('language') || ELanguage.DEFAULT;

  const handleChangeDate = (dates: [Date | null, Date | null]) => {
    const [newStartDate, newEndDate] = dates;
    updateValue(`${format(newStartDate)}|${format(newEndDate)}`);
  };

  useEffect(() => {
    registerLocale(ELanguage.EN, enUS);
    registerLocale(ELanguage.FR, fr);
    registerLocale(ELanguage.AR, ar);
  }, []);

  return (
    <div className='input-wrapper date'>
        {icon && <Icon icon={icon} iconClassName={`icon ${error && dirty ? 'invalid' : 'valid'}`} />}

        <DatePicker
          id={name}
          className={`input ${icon && 'with-icon'} ${error && dirty ? 'invalid' : 'valid'}`}
          placeholderText={icon ? labelWithAsterisk : ' '}
          dateFormat='yyyy-MM-dd'
          minDate={minDate && dayToDateMapper[minDate]}
          selected={extractDateFromRange(value, 'start')}
          startDate={extractDateFromRange(value, 'start')}
          endDate={extractDateFromRange(value, 'end')}
          selectsRange
          locale={language}
          onChange={handleChangeDate}
          disabled={disabled}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          disabledKeyboardNavigation
          onFocus={(event) => event.target.blur()}
        />

        {!icon && (
          <label
            data-testid='label'
            htmlFor={name}
            className={`label ${value ? 'stick-float' : 'label-float'} ${error && dirty ? 'invalid' : 'valid'}`}
          >
            {labelWithAsterisk}
          </label>
        )}

      {error && dirty && <p className='error-message'>{tGlobal(error)}</p>}
    </div>
  );
}
