import { EPath } from 'types/EPath';
import { TFormControlValue } from 'types/TFormControlValue';
import { IUserDto } from 'types/dtos/IUserDto';
import { SIGN_UP, USERS_CURRENT, VERIFICATION_CODE_CHECK, VERIFICATION_CODE_SEND } from 'configurations/URL';
import { setConnectedUser, removeConnectedUser } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useHttp } from 'hooks/api/useHttp';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { IFormGroup } from 'forms/group/IFormGroup';
import { showToast } from 'components/basic/Toast/Toast';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { TSignInFormControlName } from 'types/form-control-names/TSignInFormControlName';

const { removeItem } = useLocalStorage();


export const useAuthenticationApi = () => {
  const { dispatch } = useAppContext();
  const { getItem, setItem } = useLocalStorage();
  const { get, post } = useHttp();
  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };


  const getCurrentUser = async (): Promise<void> => {

    const token = getItem('token');

    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        removeItem('token');
        dispatch(removeConnectedUser());
        return;
      }

      await get<never, IUserDto>({
        url: USERS_CURRENT,
        options: { critical: true },
        onSuccess: (response) => {
          dispatch(setConnectedUser(response.value));
        }

      });
    }/*  else {
      return;
    } */
   /*  const isConnected = !!getItem('token');
    if (!isConnected) {
      return;
    } */

  };

  const sendVerificationCode = async (formGroup: IFormGroup<TSignInFormControlName>): Promise<void> => {
    await post<Record<TSignInFormControlName, TFormControlValue>, never, never>({
      url: VERIFICATION_CODE_SEND,
      params: formGroup.getNameValueRecord(),
      onSuccess: (response) => {
        setItem('phone', formGroup.getValue('phone'));
        browserHistory.push(EPath.VERIFICATION_CODE);
        showToast.success(response.message);
      }
    });
  };

  const checkVerificationCode = async <TFormControlName extends string>(
    formGroup: IFormGroup<TFormControlName>
  ): Promise<void> => {
    await get<Record<TFormControlName, TFormControlValue>, string>({
      url: VERIFICATION_CODE_CHECK,
      params: {
        phone: getItem('phone'),
        ...formGroup.getNameValueRecord()
      },
      onSuccess: async (response) => {
        setItem('token', response.value || '');
        await getCurrentUser().then(() => {
          browserHistory.push(EPath.HOME);
          showToast.success(response.message);
        });
      }
    });
  };

  const signUp = async <TFormControlName extends string>(formGroup: IFormGroup<TFormControlName>): Promise<void> => {
    await post<never, Record<TFormControlName, TFormControlValue>, IUserDto>({
      url: SIGN_UP,
      body: formGroup.getNameValueRecord(),
      onSuccess: (response) => {
        browserHistory.push(EPath.CLIENT_HOME);
        showToast.success(response.message);
        dispatch(setConnectedUser(response.value));
      }
    });
  };


  return {
    getCurrentUser,
    sendVerificationCode,
    checkVerificationCode,
    signUp
  };
};
