import { useStateWithDispatchers } from 'hooks/useStateWithDispatchers';
import { FormControl } from 'forms/control/FormControl';
import { IFormControl } from 'forms/control/IFormControl';
import { TFormControlSpec } from 'forms/control/TFormControlSpec';

export const useFormControl = <IFormControlName extends string>(
  formControlSpec: TFormControlSpec<IFormControlName>
): IFormControl<IFormControlName> => {
  const initialState = new FormControl(formControlSpec);
  return useStateWithDispatchers<IFormControl<IFormControlName>>(initialState);
};
