import { IOrderDto } from 'types/dtos/IOrderDto';
import { ICommentDto } from 'types/dtos/ICommentDto';
import { IOrdersFilterDto } from 'types/dtos/IOrdersFilterDto';
import { IUserDto } from 'types/dtos/IUserDto';
import { EActionType } from 'global-state/action/EActionType';
import { TAction } from 'global-state/action/TAction';

export const startNormalApiCall = (): TAction => ({
  type: EActionType.START_NORMAL_API_CALL
});

export const endNormalApiCall = (): TAction => ({
  type: EActionType.END_NORMAL_API_CALL
});

export const startCriticalApiCall = (): TAction => ({
  type: EActionType.START_CRITICAL_API_CALL
});

export const endCriticalApiCall = (): TAction => ({
  type: EActionType.END_CRITICAL_API_CALL
});

export const toggleIsMenuOpen = (): TAction => ({
  type: EActionType.TOGGLE_IS_MENU_OPEN
});

export const toggleIsChatOpen = (): TAction => ({
  type: EActionType.TOGGLE_IS_CHAT_OPEN
});

export const setConnectedUser = (payload: IUserDto | undefined): TAction => ({
  type: EActionType.SET_CONNECTED_USER,
  payload
});

export const removeConnectedUser = (): TAction => ({
  type: EActionType.REMOVE_CONNECTED_USER
});

export const setAllOrders = (payload: IOrderDto[] | undefined): TAction => ({
  type: EActionType.SET_ALL_ORDERS,
  payload
});

export const setFilteredOrders = (payload: IOrderDto[] | undefined): TAction => ({
  type: EActionType.SET_FILTERED_ORDERS,
  payload
});

export const setOrderDetails = (payload: IOrderDto | undefined): TAction => ({
  type: EActionType.SET_ORDER_DETAILS,
  payload
});

export const setOrdersFilter = (payload: IOrdersFilterDto | undefined): TAction => ({
  type: EActionType.SET_ORDERS_FILTER,
  payload
});

export const setOrderComments = (payload: ICommentDto[] | undefined): TAction => ({
  type: EActionType.SET_ORDER_COMMENTS,
  payload
});

export const setDriverComments = (payload: ICommentDto[] | undefined): TAction => ({
  type: EActionType.SET_DRIVER_COMMENTS,
  payload
});

export const setClientComments = (payload: ICommentDto[] | undefined): TAction => ({
  type: EActionType.SET_CLIENT_COMMENTS,
  payload
});


