import ReactDOM from 'react-dom';
import React from 'react';
import 'configurations/i18nConfig';
import { useWithAppContext } from 'global-state/context/useWithAppContext';
import { App } from 'components/layout/App/App';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'index.scss';
import 'index.override.scss';

const app = (
  <React.StrictMode>
    <React.Suspense fallback=''>{useWithAppContext(<App />)}</React.Suspense>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
