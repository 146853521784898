import { TFunction } from 'react-i18next';
import React from 'react';
import { IFormGroup } from 'forms/group/IFormGroup';
import { Button } from 'components/basic/Button/Button';
import { Input } from 'components/basic/Input/Input';

interface IFormProps<TFormControlName extends string> {
  t: TFunction<'translation'>;
  formGroup: IFormGroup<TFormControlName>;
  showSubmit?: boolean;
}

export function Form<TFormControlName extends string>({
  t,
  formGroup,
  showSubmit = true
}: IFormProps<TFormControlName>): React.ReactElement {
  const { formControls } = formGroup;

  return (
    <div className='space-y-8'>
      {formControls.map((formControl) => (
        <Input key={formControl.name} t={t} formControl={formControl} />
      ))}

      {showSubmit && (
        <Button disabled={formGroup.isNotValid()} onClick={formGroup.handleSubmit}>
          {t('submit')}
        </Button>
      )}
    </div>
  );
}
