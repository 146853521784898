import { IOrderDto } from 'types/dtos/IOrderDto';
import { IOrdersFilterDto } from 'types/dtos/IOrdersFilterDto';
import { IUserDto } from 'types/dtos/IUserDto';
import { IGlobalState } from 'global-state/IGlobalState';
import { EActionType } from 'global-state/action/EActionType';
import { TAction } from 'global-state/action/TAction';
import { TReducer } from 'global-state/reducer/TReducer';
import { ICommentDto } from 'types/dtos/ICommentDto';

export const reducer: TReducer = (state: IGlobalState, action: TAction) => {
  switch (action.type) {
    case EActionType.START_NORMAL_API_CALL:
      return {
        ...state,
        pendingApiCalls: {
          ...state.pendingApiCalls,
          normal: state.pendingApiCalls.normal + 1
        }
      };

    case EActionType.END_NORMAL_API_CALL:
      return {
        ...state,
        pendingApiCalls: {
          ...state.pendingApiCalls,
          normal: state.pendingApiCalls.normal - 1
        }
      };

    case EActionType.START_CRITICAL_API_CALL:
      return {
        ...state,
        pendingApiCalls: {
          ...state.pendingApiCalls,
          critical: state.pendingApiCalls.critical + 1
        }
      };

    case EActionType.END_CRITICAL_API_CALL:
      return {
        ...state,
        pendingApiCalls: {
          ...state.pendingApiCalls,
          critical: state.pendingApiCalls.critical - 1
        }
      };

    case EActionType.TOGGLE_IS_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen
      };

    case EActionType.TOGGLE_IS_CHAT_OPEN:
      return {
        ...state,
        isChatOpen: !state.isChatOpen
      };

    case EActionType.SET_CONNECTED_USER:
      return {
        ...state,
        connectedUser: action.payload as IUserDto
      };

    case EActionType.REMOVE_CONNECTED_USER:
      return {
        ...state,
        connectedUser: undefined
      };

    case EActionType.SET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload as IOrderDto[]
      };

    case EActionType.SET_FILTERED_ORDERS:
      return {
        ...state,
        filteredOrders: action.payload as IOrderDto[]
      };

    case EActionType.SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload as IOrderDto
      };

    case EActionType.SET_ORDERS_FILTER:
      return {
        ...state,
        ordersFilter: action.payload as IOrdersFilterDto
      };

    case EActionType.SET_ORDER_COMMENTS:
      return {
        ...state,
        orderComments: action.payload as ICommentDto[]
      };

    case EActionType.SET_DRIVER_COMMENTS:
      return {
        ...state,
        driverComments: action.payload as ICommentDto[]
      };
    case EActionType.SET_CLIENT_COMMENTS:
      return {
        ...state,
        clientComments: action.payload as ICommentDto[]
      };
    default:
      return state;
  }
};
