import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IStep } from 'types/IStep';
import { useStepperIndex } from 'hooks/useStepperIndex';
import { useUuid } from 'hooks/useUuid';
import 'components/basic/Stepper/Stepper.scss';

interface IStepperProps {
  steps: IStep[];
}

export function Stepper({ steps }: IStepperProps): React.ReactElement {
  const { generate } = useUuid();
  const { currentStepIndex, currentStep } = useStepperIndex(steps);

  const getLineClassName = (index: number) => {
    if (index <= currentStepIndex) {
      return 'line primary';
    }
    return 'line secondary';
  };

  const getStepClassName = (index: number) => {
    if (index < currentStepIndex) {
      return 'step-icon-wrapper submitted';
    }
    if (index === currentStepIndex) {
      return 'step-icon-wrapper current';
    }
    return 'step-icon-wrapper unsubmitted';
  };

  return (
    <div className='stepper'>
      <div className='steps-wrapper'>
        {steps.map((step, index) => (
          <React.Fragment key={generate()}>
            {index !== 0 && <div className={getLineClassName(index)} />}

            <div className={getStepClassName(index)}>
              <div>
                <FontAwesomeIcon icon={step.icon} className='step-icon' />
                <div className='step-label'>{step.label}</div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className='step-body-wrapper'>
        <div className='step-body'>{currentStep.body}</div>
      </div>
    </div>
  );
}
