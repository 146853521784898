import React, { Dispatch } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import 'components/pages/ClientHome/ExpandFilter/ExpandFilter.scss';

interface IOrdersFilterProps {
  isFilterVisible: boolean;
  setIsFilterVisible: Dispatch<React.SetStateAction<boolean>>;
}

export function ExpandFilter({ isFilterVisible, setIsFilterVisible }: IOrdersFilterProps): React.ReactElement {
  const { t } = usePrefixedTranslation('ExpandFilter');

  const expandFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  return (
    <button type='button' className='expand-filter' onClick={expandFilter}>
      {isFilterVisible && <FontAwesomeIcon icon={faChevronUp} />}
      {isFilterVisible ? <p>{t('collapse')}</p> : <p>{t('expand')}</p>}
      {!isFilterVisible && <FontAwesomeIcon icon={faChevronDown} />}
    </button>
  );
}
