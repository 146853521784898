import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { ELanguage } from 'types/ELanguage';
import { useLocalStorage } from 'hooks/useLocalStorage';

const { getItem } = useLocalStorage();
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: getItem('language') || ELanguage.DEFAULT,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    }
  });

export { i18n };
