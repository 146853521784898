import React from 'react';
import { useAuthenticationApi } from 'hooks/api/useAuthenticationApi';
import { useEnvironment } from 'hooks/useEnvironment';
import { useFormGroup } from 'hooks/useFormGroup';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Container } from 'components/basic/Container/Container';
import { Form } from 'components/basic/Form/Form';
import { TSignInFormControlName } from 'types/form-control-names/TSignInFormControlName';
import logo from 'assets/logo.png';

export function SignIn(): React.ReactElement {
  const { get: getEnvironment } = useEnvironment();
  const { getItem } = useLocalStorage();
  const { t } = usePrefixedTranslation('SignIn');
  const { sendVerificationCode } = useAuthenticationApi();
  const defaultPhone = getItem('phone') || getEnvironment('PREDEFINED_PHONE_NUMBER');

  const signInForm = useFormGroup<TSignInFormControlName>({
    formControlSpecs: [
      {
        name: 'phone',
        type: 'phone',
        defaultValue: defaultPhone,
        required: true
      }
    ],
    onSubmit: sendVerificationCode
  });

  return (
      <>
        <div className='container-wrapper my-28'>
        <div className='h-52'>
            <img alt='logo' src={logo} />
        </div>
        </div>
        <Container title={t('welcome')} boxed>
          <Form t={t} formGroup={signInForm} />
        </Container>
      </>
  );
}
