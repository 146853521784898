// TODO sprint 5 : install @types/react-stomp or add a new declaration (.d.ts)
// @ts-ignore
import SockJsClient from 'react-stomp';
import React from 'react';
import { IOrderDto } from 'types/dtos/IOrderDto';
import { ORDERS_WEBSOCKET, TOPIC_ORDERS } from 'configurations/URL';
import { useAppContext } from 'global-state/context/useAppContext';
import { usePhone } from 'hooks/usePhone';

interface IOrdersSubscriberProps {
  onOrderReceived: (order: IOrderDto) => void;
}

export function OrdersSubscriber({ onOrderReceived }: IOrdersSubscriberProps) {
  const { extractPhoneWithoutPrefix } = usePhone();
  const {
    state: { connectedUser }
  } = useAppContext();
  const phoneWithoutPrefix = extractPhoneWithoutPrefix(connectedUser?.phone);

  return (
    <SockJsClient
      url={`${ORDERS_WEBSOCKET}?phone=${phoneWithoutPrefix}`}
      topics={[`${TOPIC_ORDERS}/${phoneWithoutPrefix}`]}
      onMessage={onOrderReceived}
      debug={false}
    />
  );
}
