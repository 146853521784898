import { EOrderStatus } from 'types/EOrderStatus';
import { TFormControlValue } from 'types/TFormControlValue';
import { IOfferDto } from 'types/dtos/IOfferDto';
import { TOrderFormControlName } from 'types/form-control-names/TOrderFormControlName';

export interface IOrderDto {
  id: number;
  reference: string;
  origin: string;
  originCity: string;
  originLat: number;
  originLng: number;
  destination: string;
  destinationCity: string;
  destinationLat: number;
  destinationLng: number;
  deliveryDate: string;
  price?: string;
  description?: string;
  image?: string;
  activeNotifications: boolean;
  status: EOrderStatus;
  newOffers: IOfferDto[];
  allOffers: IOfferDto[];
  clientInfo?: {
    phone: string,
    fullName: string,
    profileImage: string
  };
}

export const mapToOrderForm = (order?: IOrderDto): Record<TOrderFormControlName, TFormControlValue> => {
  return {
    origin: order?.origin,
    destination: order?.destination,
    deliveryDate: order?.deliveryDate,
    price: order?.price,
    description: order?.description,
    image: order?.image
  };
};
