import React, { ReactElement, useEffect, useState } from 'react';
import {
  faArrowRight,
  faCalendarDays,
  faFilter,
  faLocationDot,
  faMagnifyingGlassLocation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ELanguage } from 'types/ELanguage';
import { IOrderDto } from 'types/dtos/IOrderDto';
import { mapToOrdersFilterForm } from 'types/dtos/IOrdersFilterDto';
import { useAppContext } from 'global-state/context/useAppContext';
import { useOrdersFilterApi } from 'hooks/api/useOrdersFilterApi';
import {useCommentApi} from 'hooks/api/useCommentApi';
import { useFormGroup } from 'hooks/useFormGroup';
import { useLoadMapScript } from 'hooks/useLoadMapScript';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMarkerFactory } from 'hooks/useMarkerFactory';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Button } from 'components/basic/Button/Button';
import { Container } from 'components/basic/Container/Container';
import { Drawer } from 'components/basic/Drawer/Drawer';
import { Form } from 'components/basic/Form/Form';
import { Map } from 'components/basic/Map/Map';
import { Tag } from 'components/basic/Tag/Tag';
import { OrdersSubscriber } from 'components/pages/DriverHome/OrdersSubscriber/OrdersSubscriber';
import { TOrdersFilterFormControlName } from 'types/form-control-names/TOrdersFilterFormControlName';
import destinationMarkerIconAR from 'assets/destination-marker-ar.png';
import destinationMarkerIconEN from 'assets/destination-marker-en.png';
import destinationMarkerIconFR from 'assets/destination-marker-fr.png';
import originMarkerIconAR from 'assets/origin-marker-ar.png';
import originMarkerIconEN from 'assets/origin-marker-en.png';
import originMarkerIconFR from 'assets/origin-marker-fr.png';
import 'components/pages/DriverHome/DriverHome.scss';
import { OrdersCard } from 'components/pages/ClientHome/OrderCard/OrderCard';
import { NoData } from 'components/basic/NoData/NoData';
import { useUuid } from 'hooks/useUuid';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { EPath } from 'types/EPath';
import { IMarker } from 'types/IMarker';
import cargoMarker from 'assets/cargo-marker.png';
import { useDate } from 'hooks/useDate';
import { setAllOrders } from 'global-state/action/actions';

export function DriverHome(): ReactElement | null {
  const { t } = usePrefixedTranslation('DriverHome');
  const { isLoaded: isMapLoaded } = useLoadMapScript();
  const { generate } = useUuid();
  const { getItem } = useLocalStorage();
  const { extractDateAsStringFromRange } = useDate();
  const { getCurrentFilter, saveCurrentFilter } = useOrdersFilterApi();
  const { showChatCommentsForDriver } = useCommentApi();
  const { generateStatelessMarker, generateMarkerWithCurrentPosition } = useMarkerFactory();
  const [isDrawerFilterOpen, setDrawerFilterOpen] = useState<boolean>(false);
  const language = getItem('language') || ELanguage.DEFAULT;
  const currentPositionMarker = generateMarkerWithCurrentPosition({ show: false });
  const [ordersMarkers, setOrdersMarkers] = useState<IMarker[]>([]);
  const {
    dispatch,
    state: { ordersFilter, allOrders }
  } = useAppContext();

  const ordersFilterForm = useFormGroup<TOrdersFilterFormControlName>({
    formControlSpecs: [
      {
        name: 'origin',
        type: 'map-position-picker',
        required: true,
        maxLength: 200,
        autoCompleteDisabled: true,
        icon: faLocationDot,
        partialMarker: {
          show: false,
          circle: {
            show: false,
            color: '#EB6730',
            radiusInMeters: 0
          }
        }
      },
      {
        name: 'originRadius',
        type: 'range',
        required: true,
        minValue: 1,
        maxValue: 99
      },
      {
        name: 'destination',
        type: 'map-position-picker',
        maxLength: 200,
        autoCompleteDisabled: true,
        icon: faLocationDot,
        partialMarker: {
          show: false,
          circle: {
            show: false,
            color: '#479CE3',
            radiusInMeters: 0
          }
        }
      },
      {
        name: 'destinationRadius',
        type: 'range',
        maxValue: 99
      },
      {
        name: 'deliveryDate',
        type: 'date-range',
        required: true,
        icon: faCalendarDays
      }
    ],
    onSubmit: (formGroup) => {
      saveCurrentFilter(formGroup);
      setDrawerFilterOpen(false);
    }
  });

  const removeOrderByReference = (reference: string): IOrderDto[] => {
    return allOrders.filter((item) => item.reference !== reference);
  }

  const addOrderInTop = (order: IOrderDto, newAllOrders: IOrderDto[]): IOrderDto[] => {
    return [order, ...newAllOrders];
  }

  const handleOrderReceived = (newOrder: IOrderDto) => {
    const allOrdersWithoutNewOrder = removeOrderByReference(newOrder.reference);
    const allOrdersWithNewOrderInTop = addOrderInTop(newOrder, allOrdersWithoutNewOrder);
    dispatch(setAllOrders(allOrdersWithNewOrderInTop));
  };

  const showDrawerFilter = () => {
    setDrawerFilterOpen(true);
  };

  const toggleDrawerFilter = () => {
    setDrawerFilterOpen(!isDrawerFilterOpen);
  };

  const navigateToDetails = (reference: string) => () => {
    browserHistory.push(`${EPath.ORDER_DETAILS}/${reference}`);
  };

  useEffect(() => {
    getCurrentFilter();
    showChatCommentsForDriver();
  }, []);

  useEffect(() => {
    if (!ordersFilter) {
      return;
    }

    ordersFilterForm.updateFormValues(mapToOrdersFilterForm(ordersFilter));

    ordersFilterForm.patchMarker('origin', {
      show: true,
      position: {
        lat: ordersFilter.originLat,
        lng: ordersFilter.originLng
      },
      circle: {
        ...ordersFilterForm.getMarker('origin').circle,
        radiusInMeters: ordersFilter.originRadius * 1000,
        show: true
      }
    });

    if (ordersFilter.destinationLat && ordersFilter.destinationLng) {
      ordersFilterForm.patchMarker('destination', {
        show: true,
        position: {
          lat: ordersFilter.destinationLat,
          lng: ordersFilter.destinationLng
        },
        circle: {
          ...ordersFilterForm.getMarker('destination').circle,
          radiusInMeters: (ordersFilter.destinationRadius || 0) * 1000,
          show: true
        }
      });
    }
  }, [ordersFilter]);

  useEffect(() => {
    const originMarker = ordersFilterForm.getMarker('origin');
    const originRadius = Number(ordersFilterForm.getValue('originRadius'));

    ordersFilterForm.patchMarker('origin', {
      circle: {
        ...originMarker.circle,
        radiusInMeters: originRadius * 1000,
        show: true
      }
    });
  }, [ordersFilterForm.getValue('originRadius')]);

  useEffect(() => {
    const destinationMarker = ordersFilterForm.getMarker('destination');
    const destinationRadius = Number(ordersFilterForm.getValue('destinationRadius') || 0);

    if (destinationMarker.position.lat && destinationMarker.position.lng) {
      ordersFilterForm.patchMarker('destination', {
        circle: {
          ...ordersFilterForm.getMarker('destination').circle,
          radiusInMeters: destinationRadius * 1000,
          show: true
        }
      });
    }
  }, [ordersFilterForm.getValue('destinationRadius')]);

  useEffect(() => {
    if (language === ELanguage.EN) {
      ordersFilterForm.patchMarker('origin', { icon: originMarkerIconEN });
      ordersFilterForm.patchMarker('destination', { icon: destinationMarkerIconEN });
      return;
    }
    if (language === ELanguage.FR) {
      ordersFilterForm.patchMarker('origin', { icon: originMarkerIconFR });
      ordersFilterForm.patchMarker('destination', { icon: destinationMarkerIconFR });
      return;
    }
    if (language === ELanguage.AR) {
      ordersFilterForm.patchMarker('origin', { icon: originMarkerIconAR });
      ordersFilterForm.patchMarker('destination', { icon: destinationMarkerIconAR });
    }
  }, [language]);

  useEffect(() => {
    if (isMapLoaded) {
      const newOrdersMarkers: IMarker[] = allOrders.map((order) => generateStatelessMarker({
        icon: cargoMarker,
        position: {
          lat: order.originLat,
          lng: order.originLng
        }
      }));
      setOrdersMarkers(newOrdersMarkers);
    }
  }, [allOrders]);

  if (!isMapLoaded) {
    return null;
  }

  return (
    <>
      <OrdersSubscriber onOrderReceived={handleOrderReceived} />
      <Container fullWidth className='driver-home' title={t('title')}>
        <div className='show-only-map-wrapper'>
          <Map
            isFitBounds
            type='show-only'
            center={currentPositionMarker.position}
            markers={[
              ...ordersMarkers,
              currentPositionMarker,
              ordersFilterForm.getMarker('origin'),
              ordersFilterForm.getMarker('destination'),
            ]}
          />
        </div>

        <div className='filter-recap'>
          <div dir='ltr' className='tags-wrapper'>
            <Tag
              className='flex-none truncate w-[72px]'
              type='SECONDARY'
              label={
                <span dir='ltr'>
                  <FontAwesomeIcon className='text-gray-400 w-3 mr-1' icon={faMagnifyingGlassLocation} />
                  {ordersFilterForm.getValue('originRadius')}Km
                </span>
              }
            />
            <Tag
              className='flex-1 truncate'
              type='SECONDARY'
              label={
                <span dir='ltr'>
                  <FontAwesomeIcon className='text-gray-400 w-3 mr-1' icon={faLocationDot} />
                  {ordersFilterForm.getValue('origin')}
                </span>
              }
            />

            <FontAwesomeIcon className='text-gray-400 w-3' icon={faArrowRight} />

            <Tag
              className='flex-none truncate w-[72px]'
              type='SECONDARY'
              label={
                <span dir='ltr'>
                  <FontAwesomeIcon className='text-gray-400 w-3 mr-1' icon={faMagnifyingGlassLocation} />
                  {ordersFilterForm.getValue('destinationRadius') || '--'}Km
                </span>
              }
            />

            <Tag
              className='flex-1 truncate'
              type='SECONDARY'
              label={
                <span dir='ltr'>
                  <FontAwesomeIcon className='text-gray-400 w-3 mr-1' icon={faLocationDot} />
                  {ordersFilterForm.getValue('destination') || t('anywhere')}
                </span>
              }
            />
          </div>

          <div dir='ltr' className='tags-wrapper'>
            <Tag
              className='flex-1 truncate'
              type='SECONDARY'
              label={
                <span dir='ltr'>
                  <FontAwesomeIcon className='text-gray-400 w-3 mr-1' icon={faCalendarDays} />
                  {extractDateAsStringFromRange(ordersFilterForm.getValue('deliveryDate'), 'start')}
                </span>
              }
            />

            <FontAwesomeIcon className='text-gray-400 w-3' icon={faArrowRight} />

            <Tag
              className='flex-1 truncate'
              type='SECONDARY'
              label={
                <span dir='ltr'>
                  <FontAwesomeIcon className='text-gray-400 w-3 mr-1' icon={faCalendarDays} />
                  {extractDateAsStringFromRange(ordersFilterForm.getValue('deliveryDate'), 'end') || t('anytime')}
                </span>
              }
            />
          </div>
        </div>

        <div className='filter-button-wrapper' dir='ltr'>
          <Button type='SECONDARY' onClick={showDrawerFilter}>
            <FontAwesomeIcon className='mr-1' icon={faFilter} />
            {t('filter')}
          </Button>
        </div>

        <Drawer
          title={t('filter')}
          type='bottom'
          fullScreen
          hideCloseButton
          isOpen={isDrawerFilterOpen}
          toggle={toggleDrawerFilter}
        >
          <Container fullHeight>
            <Form t={t} formGroup={ordersFilterForm} />
          </Container>
        </Drawer>

        <div className='orders-list'>
          {allOrders.length > 0 ? (
            allOrders.map((order) => (
              <OrdersCard
                key={generate()}
                type='driver-mode'
                order={order}
                onClick={navigateToDetails(order.reference)}
              />
            ))
          ) : (
            <NoData type='clientsOrdersList' />
          )}
        </div>
      </Container>
    </>
  );
}
