import { IStep } from 'types/IStep';

const getStepIndex = (steps: IStep[]) => {
  const stepIndex = steps.findIndex((step) => step.isCurrentStep);
  if (stepIndex === -1) {
    return 0;
  }

  return stepIndex;
};

export const useStepperIndex = (steps: IStep[]) => {
  const currentStepIndex = getStepIndex(steps);
  const currentStep = steps[currentStepIndex];

  return {
    currentStepIndex,
    currentStep
  };
};
