import React, { ReactNode } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/basic/Button/Button';
import 'components/basic/Drawer/Drawer.scss';

export type TDrawer = 'bottom' | 'right';

interface IDrawerProps {
  children: ReactNode;
  type?: TDrawer;
  title?: string;
  hideCloseButton?: boolean;
  isOpen: boolean;
  toggle: () => void;
  fullScreen?: boolean;
}

export function Drawer({
  children,
  type = 'right',
  title = '',
  isOpen,
  hideCloseButton = false,
  toggle,
  fullScreen = false
}: IDrawerProps): React.ReactElement {
  return (
    <div className={`drawer ${type} ${isOpen ? 'opened' : 'closed'}`}>
      <section className={`drawer-active ${type} ${isOpen ? 'opened' : 'closed'} ${!fullScreen && 'partial-screen'} `}>
        <div className='drawer-header'>
          <span dir='ltr'>{title}</span>
          {!hideCloseButton && <Button type='MINIATURE' onClick={toggle}>
            <FontAwesomeIcon className='text-xl w-5 h-5' icon={faXmark} />
          </Button>}
        </div>
        {children}
      </section>
      <section className='drawer-dead' aria-hidden='true' onClick={toggle} />;
    </div>
  );
}
