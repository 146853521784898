import { TFunction } from 'react-i18next';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';

interface IUseTranslation {
  tGlobal: TFunction<'translation'>;
}

export const useGlobalTranslation = (): IUseTranslation => {
  const { t } = usePrefixedTranslation('Global');
  return { tGlobal: t };
};
