import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/basic/Button/Button';

interface IProfileButtonProps {
  setIsOpen: () => void;
  profileImage?: string;
}

export function ProfileButton({ setIsOpen, profileImage }: IProfileButtonProps): React.ReactElement {
  return (
    <Button type='ROUNDED' onClick={setIsOpen}>
      {profileImage ? (
        <img className='profile-image' src={profileImage} alt='Profile'/>
      ) : (
        <FontAwesomeIcon icon={faUser} />
      )}
    </Button>
  );
}
