import React, { ChangeEvent } from 'react';
import { useUuid } from 'hooks/useUuid';
import 'components/basic/CheckBox/CheckBox.scss';

interface ICheckBoxProps {
  className?: string;
  leftLabel: string;
  rightLabel: string;
  onChange?: (checked: boolean) => void;
}

export function CheckBox({ className, leftLabel, rightLabel, onChange }: ICheckBoxProps): React.ReactElement {
  const { generate } = useUuid();
  const id = generate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <label htmlFor={id} dir='ltr' className={`radio-group-wrapper ${className}`}>
      <input onChange={handleChange} id={id} type='checkbox' className='hidden peer' />
      <span className='radio-element rounded-l-md bg-primary-500 text-white peer-checked:bg-white peer-checked:text-gray-600'>
        {leftLabel}
      </span>
      <span className='radio-element rounded-r-md bg-white text-gray-600 peer-checked:bg-primary-500 peer-checked:text-white'>
        {rightLabel}
      </span>
    </label>
  );
}
