import { useStateWithDispatchers } from 'hooks/useStateWithDispatchers';
import { FormGroup } from 'forms/group/FormGroup';
import { IFormGroup } from 'forms/group/IFormGroup';
import { IFormGroupSpec } from 'forms/group/IFormGroupSpec';

export const useFormGroup = <TFormControlName extends string>(
  formGroupSpec: IFormGroupSpec<TFormControlName>
): IFormGroup<TFormControlName> => {
  const initialState = new FormGroup<TFormControlName>(formGroupSpec);
  return useStateWithDispatchers<IFormGroup<TFormControlName>>(initialState);
};
