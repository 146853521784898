import React from 'react';
import { faIdCard, faTruck } from '@fortawesome/free-solid-svg-icons';
import { IStep } from 'types/IStep';
import { EDriverStatus } from 'types/dtos/IUserDto';
import { useAppContext } from 'global-state/context/useAppContext';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Container } from 'components/basic/Container/Container';
import { Stepper } from 'components/basic/Stepper/Stepper';
import { DriverLicenseForm } from 'components/pages/SwitchToDriverMode/DriverLicenseForm/DriverLicenseForm';
import { VehicleForm } from 'components/pages/SwitchToDriverMode/VehicleForm/VehicleForm';

export function SwitchToDriverMode(): React.ReactElement {
  const { t } = usePrefixedTranslation('SwitchToDriverMode');
  const {
    state: { connectedUser }
  } = useAppContext();

  const steps: IStep[] = [
    {
      icon: faIdCard,
      label: t('driverLicense'),
      body: <DriverLicenseForm />,
      isCurrentStep: connectedUser?.driverStatus === EDriverStatus.STEP_1
    },
    {
      icon: faTruck,
      label: t('vehicle'),
      body: <VehicleForm />,
      isCurrentStep: connectedUser?.driverStatus === EDriverStatus.STEP_2
    }
  ];

  return (
    <Container title={t('title')}>
      <Stepper steps={steps} />
    </Container>
  );
}
