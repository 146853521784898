import React, { ReactNode } from 'react';
import { EDriverStatus } from 'types/dtos/IUserDto';
import 'components/basic/Tag/Tag.scss';

export type TTag = EDriverStatus | 'PRIMARY' | 'SUCCESS' | 'SECONDARY' | 'DANGER' | 'WARNING';

interface ITagProps {
  type?: TTag;
  label?: ReactNode;
  className?: string;
}

export function Tag({ type = 'PRIMARY', label = '', className }: ITagProps): React.ReactElement {
  return <span className={`tag ${type} ${className}`}>{label}</span>;
}
