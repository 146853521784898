import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import React, { ReactNode, useLayoutEffect, useState } from 'react';

interface ICustomBrowserRouter {
  children: ReactNode;
}

export const browserHistory = createBrowserHistory();

export function CustomBrowserRouter({ children }: ICustomBrowserRouter): React.ReactElement {
  const [state, setState] = useState({
    action: browserHistory.action,
    location: browserHistory.location
  });

  useLayoutEffect(() => {
    browserHistory.listen(setState);
  }, [browserHistory]);

  return (
    <Router navigationType={state.action} location={state.location} navigator={browserHistory}>
      {children}
    </Router>
  );
}
