import { useEnvironment } from 'hooks/useEnvironment';

const { get } = useEnvironment();
const API_BASE_URL = get('API_BASE_URL');
const AUTHENTICATION_BASE_URL = `${API_BASE_URL}`;
const ORDER_BASE_URL = `${API_BASE_URL}`;

export const USERS_CURRENT = `${AUTHENTICATION_BASE_URL}/user/current`;
export const VERIFICATION_CODE_SEND = `${AUTHENTICATION_BASE_URL}/verification-code/send`;
export const VERIFICATION_CODE_CHECK = `${AUTHENTICATION_BASE_URL}/verification-code/check`;
export const SIGN_UP = `${AUTHENTICATION_BASE_URL}/sign-up`;

export const DRIVER_LICENSE = `${AUTHENTICATION_BASE_URL}/driver-license`;
export const VEHICLE = `${AUTHENTICATION_BASE_URL}/vehicle`;
export const USERS_CURRENT_AS_CLIENT = `${AUTHENTICATION_BASE_URL}/users/current/as-client`;
export const USERS_CURRENT_AS_DRIVER = `${AUTHENTICATION_BASE_URL}/users/current/as-driver`;

export const ORDERS = `${ORDER_BASE_URL}/orders`;
export const ORDER = `${ORDER_BASE_URL}/order`;
export const ORDER_BY_REFERENCE = `${ORDER_BASE_URL}/order/by-reference`;
export const ORDER_NOTIFICATIONS_TOGGLE = `${ORDER_BASE_URL}/order/notifications/toggle`;
export const ORDER_ARCHIVE = `${ORDER_BASE_URL}/order/archive`;
export const ORDER_ACTIVE = `${ORDER_BASE_URL}/order/active`;
export const ORDER_SHOW_PHONE = `${ORDER_BASE_URL}/order/show-phone`;
export const ORDERS_BY_CURRENT_FILTER = `${ORDER_BASE_URL}/orders-by-current-filter`;
export const ORDER_SHOW_COMMENTS = `${API_BASE_URL}/comments-by-order`;
export const DRIVER_SHOW_COMMENTS = `${API_BASE_URL}/driver-comments-by-order`;

export const CLIENT_SHOW_COMMENTS = `${API_BASE_URL}/client-comments-by-order`;

export const ORDERS_FILTER_CURRENT = `${ORDER_BASE_URL}/orders/filter/current`;

export const COMMENT = `${API_BASE_URL}/comment/new`;

export const ORDERS_WEBSOCKET = `${ORDER_BASE_URL}/orders-websocket`;
export const TOPIC_ORDERS = '/topic/orders';
