import Toggle from 'react-toggle';
import React, { ChangeEvent, ReactElement } from 'react';
import 'components/basic/ToggleButton/ToggleButton.scss';

interface IToggleButtonProps {
  checked: boolean;
  onChange: (event: ChangeEvent) => void;
  checkedIcon?: ReactElement;
  uncheckedIcon?: ReactElement;
}

export function ToggleButton({ checked, onChange, checkedIcon, uncheckedIcon }: IToggleButtonProps): ReactElement {
  return (
    <span className='flex items-center text-white text-xs'>
      <Toggle checked={checked} onChange={onChange} icons={{ checked: checkedIcon, unchecked: uncheckedIcon }} />
    </span>
  );
}
