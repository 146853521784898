import { EDay } from 'types/EDay';
import { EValidatorError } from 'types/EValidatorError';
import { IValidator } from 'types/IValidator';
import { TFileSize } from 'types/TFileSize';
import { useDate } from 'hooks/useDate';
import { useFile } from 'hooks/useFile';

export const useValidator = () => {
  const { isBefore } = useDate();
  const { isValidBase64Size } = useFile();
  const { extractDateAsStringFromRange } = useDate();

  return {
    required: {
      validate: (value: string): EValidatorError => {
        if (!value || !value.trim()) {
          return EValidatorError.REQUIRED;
        }
        return EValidatorError.VALID;
      }
    },

    phone: {
      validate: (value: string): EValidatorError => {
        const pattern = /^(0)([6-7])[0-9]{8}$/;
        if (value && !pattern.test(value)) {
          return EValidatorError.PHONE ;
        }

        return EValidatorError.VALID;
      }
    },

    email: {
      validate: (value: string): EValidatorError => {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (value && !pattern.test(value)) {
          return EValidatorError.EMAIL;
        }

        return EValidatorError.VALID;
      }
    },

    maxLength: (maxLength: number): IValidator => ({
      validate: (value: string): EValidatorError => {
        if (value.length > maxLength) {
          return EValidatorError.MAX_LENGTH;
        }

        return EValidatorError.VALID;
      }
    }),

    exactLength: (length: number): IValidator => ({
      validate: (value: string): EValidatorError => {
        if (value.length !== length) {
          return EValidatorError.EXACT_LENGTH;
        }

        return EValidatorError.VALID;
      }
    }),

    minDate: (day: EDay): IValidator => ({
      validate: (value: string): EValidatorError => {
        if (isBefore(value, day)) {
          return EValidatorError.MIN_DATE_TODAY;
        }

        return EValidatorError.VALID;
      }
    }),

    minDateRange: (day: EDay): IValidator => ({
      validate: (value: string): EValidatorError => {
        const startDate = extractDateAsStringFromRange(value, 'start');

        if (isBefore(startDate, day)) {
          return EValidatorError.MIN_DATE_TODAY;
        }

        return EValidatorError.VALID;
      }
    }),

    minValue: (minValue: number): IValidator => ({
      validate: (value: string): EValidatorError => {
        if (+value < minValue) {
          return EValidatorError.MIN_VALUE;
        }

        return EValidatorError.VALID;
      }
    }),

    maxFileSize: (maxSize: TFileSize): IValidator => ({
      validate: (value: string): EValidatorError => {
        if (maxSize === '2MB') {
          if (value && !isValidBase64Size(value, 2)) {
            return EValidatorError.IMAGE_MAX_SIZE;
          }

          return EValidatorError.VALID;
        }

        return EValidatorError.VALID;
      }
    })
  };
};
