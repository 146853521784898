import React, { useState } from 'react';
import { EPath } from 'types/EPath';
import { useAppContext } from 'global-state/context/useAppContext';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { useUuid } from 'hooks/useUuid';
import { Container } from 'components/basic/Container/Container';
import { NoData } from 'components/basic/NoData/NoData';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { ExpandFilter } from 'components/pages/ClientHome/ExpandFilter/ExpandFilter';
import { OrdersCard } from 'components/pages/ClientHome/OrderCard/OrderCard';
import { OrdersFilter } from 'components/pages/ClientHome/OrdersFilter/OrdersFilter';
import 'components/pages/ClientHome/ClientHome.scss';

export function ClientHome(): React.ReactElement {
  const { t } = usePrefixedTranslation('ClientHome');
  const { generate } = useUuid();
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const {
    state: { filteredOrders }
  } = useAppContext();

  const navigateToDetails = (reference: string) => () => {
    browserHistory.push(`${EPath.MY_ORDER_DETAILS}/${reference}`);
  };

  return (
    <Container fullWidth title={t('title')}>
      <div className='filter-wrapper'>
        <OrdersFilter visible={isFilterVisible} />
        <ExpandFilter isFilterVisible={isFilterVisible} setIsFilterVisible={setIsFilterVisible} />
      </div>

      <div className='orders-list'>
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order) => (
            <OrdersCard type='client-mode' key={generate()} order={order} onClick={navigateToDetails(order.reference)} />
          ))
            ) : (
            <NoData type='myOrdersList' />
        )}
      </div>
    </Container>
  );
}
