import { ICommentDto } from 'types/dtos/ICommentDto';
import { IOrderDto } from 'types/dtos/IOrderDto';
import { IOrdersFilterDto } from 'types/dtos/IOrdersFilterDto';
import { IUserDto } from 'types/dtos/IUserDto';

export interface IGlobalState {
  pendingApiCalls: {
    critical: number;
    normal: number;
  };
  isMenuOpen: boolean;
  isChatOpen: boolean;
  connectedUser: IUserDto | undefined;
  allOrders: IOrderDto[];
  filteredOrders: IOrderDto[];
  orderDetails: IOrderDto | undefined;
  ordersFilter: IOrdersFilterDto | undefined;
  orderComments: ICommentDto[];
  driverComments: ICommentDto[];
  clientComments: ICommentDto[];

}

export const initialState: IGlobalState = {
  pendingApiCalls: {
    critical: 0,
    normal: 0
  },
  isMenuOpen: false,
  isChatOpen: false,
  connectedUser: undefined,
  allOrders: [],
  filteredOrders: [],
  orderDetails: undefined,
  ordersFilter: undefined,
  orderComments: [],
  driverComments: [],
  clientComments: []
};
