/* global google */
import React, { ChangeEvent } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { EDay } from 'types/EDay';
import { EValidatorError } from 'types/EValidatorError';
import { defaultMarker, IMarker } from 'types/IMarker';
import { IValidator } from 'types/IValidator';
import { TDispatch } from 'types/TDispatch';
import { TFileSize } from 'types/TFileSize';
import { TInput } from 'types/TInput';
import { TPartialDispatch } from 'types/TPartialDispatch';
import { useFile } from 'hooks/useFile';
import { useValidator } from 'hooks/useValidator';
import { IFormControl } from 'forms/control/IFormControl';
import { TFormControlSpec } from 'forms/control/TFormControlSpec';
import { useMarkerFactory } from 'hooks/useMarkerFactory';

export class FormControl<TFormControlName extends string> implements IFormControl<TFormControlName> {
  name: TFormControlName;

  type: TInput = 'text';

  label: string = '';

  icon?: IconDefinition;

  value: string = '';

  error: EValidatorError = EValidatorError.VALID;

  dirty?: boolean;

  disabled?: boolean;

  required?: boolean;

  autoCompleteDisabled?: boolean;

  exactLength?: number;

  maxFileSize?: TFileSize;

  minValue?: number;

  maxValue?: number;

  minDate?: EDay;

  marker: IMarker = { ...defaultMarker };

  isOpenMapPositionPicker?: boolean;

  validators: IValidator[] = [];

  dispatch: TDispatch<IFormControl<TFormControlName>>;

  patch: TPartialDispatch<IFormControl<TFormControlName>>;

  handlePlaceSelected?: (place: google.maps.places.PlaceResult) => void;

  onChange?: (value: string) => void;

  constructor(formControlSpec: TFormControlSpec<TFormControlName>) {
    this.dispatch = () => {
    };
    this.patch = () => {
    };
    this.name = formControlSpec.name;
    this.disabled = formControlSpec.disabled;
    this.required = formControlSpec.required;
    this.type = formControlSpec.type || 'text';
    this.label = formControlSpec.name;
    this.prepareValidators(formControlSpec);

    if ('icon' in formControlSpec) {
      this.icon = formControlSpec.icon;
    }
    if ('defaultValue' in formControlSpec) {
      this.value = formControlSpec.defaultValue || '';
    }
    if ('autoCompleteDisabled' in formControlSpec) {
      this.autoCompleteDisabled = formControlSpec.autoCompleteDisabled;
    }
    if ('exactLength' in formControlSpec) {
      this.exactLength = formControlSpec.exactLength;
    }
    if ('minValue' in formControlSpec) {
      this.minValue = formControlSpec.minValue;
    }
    if ('maxValue' in formControlSpec) {
      this.maxValue = formControlSpec.maxValue;
    }
    if ('minDate' in formControlSpec) {
      this.minDate = formControlSpec.minDate;
    }
    if ('maxFileSize' in formControlSpec) {
      this.maxFileSize = formControlSpec.maxFileSize;
    }
    if ('onChange' in formControlSpec) {
      this.onChange = formControlSpec.onChange;
    }
    if (formControlSpec.type === 'map-position-picker') {
      this.marker = FormControl.generateMarker(formControlSpec.partialMarker);
    }
    if (formControlSpec.type === 'map-position-picker') {
      this.handlePlaceSelected = formControlSpec.handlePlaceSelected;
    }

    this.validate();
  }

  handleFocus = (event?: React.FocusEvent<HTMLInputElement>) => {
    if (this.type === 'map-position-picker') {
      this.toggleOpenMapPositionPicker();
      event?.target.blur();
    }
  };

  handleChange = async (event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    const { getFileContent } = useFile();
    if (this.type === 'image') {
      this.updateValue(await getFileContent(event));
      return;
    }
    this.updateValue(event.target.value);
    this.onChange?.(event.target.value);
  };

  updateValue = (value: string) => {
    this.setValue(value);
    this.markAsDirty();
    this.validate();
    this.dispatch(this);
  };

  toggleOpenMapPositionPicker = () => {
    this.isOpenMapPositionPicker = !this.isOpenMapPositionPicker;
    this.dispatch(this);
  };

  patchMarker = (partialMarker: Partial<IMarker>) => {
    this.setMarker({ ...this.marker, ...partialMarker });
    if (partialMarker.address) {
      this.updateValue(partialMarker.address);
      return;
    }
    this.dispatch(this);
  };

  private setValue = (value: string) => {
    this.value = value;
  };

  private setMarker = (marker: IMarker) => {
    this.marker = marker;
  };

  private markAsDirty = () => {
    this.dirty = true;
  };

  private validate = () => {
    this.error = EValidatorError.VALID;
    this.validators?.forEach((validatorElement) => {
      if (!this.error) {
        this.error = validatorElement.validate(this.value);
      }
    });
  };

  private prepareValidators = (formControlSpec: TFormControlSpec<TFormControlName>): void => {
    if (!formControlSpec) {
      return;
    }
    const validator = useValidator();
    if (formControlSpec.required) {
      this.validators.push(validator.required);
    }
    if (formControlSpec.type === 'phone') {
      this.validators.push(validator.phone);
    }
    if (formControlSpec.type === 'email') {
      this.validators.push(validator.email);
    }
    if ('exactLength' in formControlSpec && formControlSpec.exactLength) {
      this.validators.push(validator.exactLength(formControlSpec.exactLength));
    }
    if ('maxLength' in formControlSpec && formControlSpec.maxLength) {
      this.validators.push(validator.maxLength(formControlSpec.maxLength));
    }
    if ('maxFileSize' in formControlSpec && formControlSpec.maxFileSize) {
      this.validators.push(validator.maxFileSize(formControlSpec.maxFileSize));
    }
    if ('minDate' in formControlSpec && formControlSpec.minDate) {
      this.validators.push(validator.minDate(formControlSpec.minDate));
    }
    if ('minDate' in formControlSpec && formControlSpec.minDate) {
      this.validators.push(validator.minDate(formControlSpec.minDate));

      if (formControlSpec.type === 'date-range') {
        this.validators.push(validator.minDateRange(formControlSpec.minDate));
      }
    }
    if ('minValue' in formControlSpec && (formControlSpec.minValue || formControlSpec.minValue === 0)) {
      this.validators.push(validator.minValue(formControlSpec.minValue));
    }
  };

  private static generateMarker = (partialMarker?: Partial<IMarker>) => {
    const { generateStatelessMarker } = useMarkerFactory();
    return generateStatelessMarker(partialMarker);
  };
}
