import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { useEnvironment } from 'hooks/useEnvironment';

const libraries: Libraries = ['places'];

export const useLoadMapScript = () => {
  const { get } = useEnvironment();
  return useLoadScript({
    googleMapsApiKey: get('GOOGLE_MAPS_API_KEY'),
    libraries
  });
};
