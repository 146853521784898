import React, { useEffect } from 'react';
import { mapToSignUpForm } from 'types/dtos/IUserDto';
import { useAppContext } from 'global-state/context/useAppContext';
import { useAuthenticationApi } from 'hooks/api/useAuthenticationApi';
import { useFormGroup } from 'hooks/useFormGroup';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Container } from 'components/basic/Container/Container';
import { Form } from 'components/basic/Form/Form';
import { TSignUpFormControlName } from 'types/form-control-names/TSignUpFormControlName';
import logo from 'assets/logo.png';
import user from 'assets/user.jpeg';

export function SignUp(): React.ReactElement {
  const { t } = usePrefixedTranslation('SignUp');
  const {
    state: { connectedUser }
  } = useAppContext();
  const { signUp } = useAuthenticationApi();

  const signUpForm = useFormGroup<TSignUpFormControlName>({
    formControlSpecs: [
      {
        name: 'phone',
        type: 'phone',
        disabled: true,
        required: true,
      },
      {
        name: 'profileImage',
        type: 'image',
        defaultValue: user,
        required: false,
        maxFileSize: '2MB',
       
      },
      {
        name: 'email',
        type: 'email',
        maxLength: 50
      },
      {
        name: 'firstName',
        required: true,
        maxLength: 50
      },
      {
        name: 'lastName',
        required: true,
        maxLength: 50
      },
      {
        name: 'address',
        required: true,
        maxLength: 200
      },
      {
        name: 'companyName',
        maxLength: 50
      }
    ],
    onSubmit: signUp
  });

  useEffect(() => {
    signUpForm.updateFormValues(mapToSignUpForm(connectedUser));
  }, [connectedUser]);

  return (
      <>
        <div className='container-wrapper my-28'>
          <div className='h-52'>
              <img alt='logo' src={logo} />
          </div>
        </div>
        <Container title={t('personalInformation')} boxed>
          <Form t={t} formGroup={signUpForm} />
        </Container>
      </>
  );
}
