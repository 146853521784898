import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import { useAuthenticationApi } from 'hooks/api/useAuthenticationApi';
import { PageLoader } from 'components/basic/PageLoader/PageLoader';
import { CustomBrowserRouter } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { Header } from 'components/layout/Header/Header';
import { Page } from 'components/layout/Page/Page';
import { Router } from 'components/layout/Router/Router';
import { SideMenu } from 'components/layout/SideMenu/SideMenu';
import { ChatMenu } from 'components/layout/ChatMenu/ChatMenu';
import { ClientChatMenu } from 'components/layout/ChatMenu/ClientChatMenu';
import { EConnectedAs } from 'types/dtos/IUserDto';
import { useAppContext } from 'global-state/context/useAppContext';


export function App(): React.ReactElement {

  const { getCurrentUser } = useAuthenticationApi();
    const {
        state: {
            connectedUser
        }
    } = useAppContext();
    const isConnectedAsClient = connectedUser?.connectedAs === EConnectedAs.CLIENT;
    const isConnectedAsDriver = connectedUser?.connectedAs === EConnectedAs.DRIVER;
  useEffect(() => {
    getCurrentUser();
  }, []);
  return (
    <CustomBrowserRouter>
      <Header />
      <Page>
        <Router />
      </Page>
      <SideMenu />
        {isConnectedAsDriver && (
            <ChatMenu />
        )}
        {isConnectedAsClient && (
            <ClientChatMenu />
        )}
        <ToastContainer />
      <PageLoader />
    </CustomBrowserRouter>
  );
}
