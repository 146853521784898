import React, { useEffect } from 'react';
import { EDay } from 'types/EDay';
import { useAppContext } from 'global-state/context/useAppContext';
import { useSwitchToDriverModeApi } from 'hooks/api/useSwitchToDriverModeApi';
import { useFormGroup } from 'hooks/useFormGroup';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Form } from 'components/basic/Form/Form';
import { TDriverLicenseFormControlName } from 'types/form-control-names/TDriverLicenseFormControlName';

export function DriverLicenseForm(): React.ReactElement {
  const { t } = usePrefixedTranslation('DriverLicenseForm');
  const {
    state: { connectedUser }
  } = useAppContext();
  const { getDriverLicense, saveDriverLicense } = useSwitchToDriverModeApi();

  const driverLicenseForm = useFormGroup<TDriverLicenseFormControlName>({
    formControlSpecs: [
      {
        name: 'licenseNumber',
        required: true,
        maxLength: 50
      },
      {
        name: 'imageRecto',
        type: 'image',
        required: true,
        maxFileSize: '2MB'
      },
      {
        name: 'imageVerso',
        type: 'image',
        required: true,
        maxFileSize: '2MB'
      },
      {
        name: 'expirationDate',
        type: 'date',
        required: true,
        minDate: EDay.TOMORROW
      }
    ],
    onSubmit: saveDriverLicense
  });

  useEffect(() => {
    if (connectedUser?.driverStatus) {
      getDriverLicense(driverLicenseForm);
    }
  }, [connectedUser?.driverStatus]);

  return <Form t={t} formGroup={driverLicenseForm} />;
}
