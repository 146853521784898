import { TFormControlValue } from 'types/TFormControlValue';
import { TSignUpFormControlName } from 'types/form-control-names/TSignUpFormControlName';

export enum EClientStatus {
  INCOMPLETE_PROFILE = 'INCOMPLETE_PROFILE',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}

export enum EDriverStatus {
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}

export enum EConnectedAs {
  CLIENT = 'CLIENT',
  DRIVER = 'DRIVER'
}

export interface IUserDto {
  phone: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  companyName?: string;
  profileImage?: string;
  clientStatus?: EClientStatus;
  driverStatus?: EDriverStatus;
  connectedAs?: EConnectedAs;
}

export const mapToSignUpForm = (userDto?: IUserDto): Record<TSignUpFormControlName, TFormControlValue> => {
  return {
    phone: userDto?.phone,
    profileImage: userDto?.profileImage,
    email: userDto?.email,
    firstName: userDto?.firstName,
    lastName: userDto?.lastName,
    address: userDto?.address,
    companyName: userDto?.companyName
  };
};
