export enum EActionType {
  START_NORMAL_API_CALL = 'START_NORMAL_API_CALL',
  END_NORMAL_API_CALL = 'END_NORMAL_API_CALL',
  START_CRITICAL_API_CALL = 'START_CRITICAL_API_CALL',
  END_CRITICAL_API_CALL = 'END_CRITICAL_API_CALL',
  TOGGLE_IS_MENU_OPEN = 'TOGGLE_IS_MENU_OPEN',
  TOGGLE_IS_CHAT_OPEN  = 'TOGGLE_IS_CHAT_OPEN',
  SET_CONNECTED_USER = 'SET_CONNECTED_USER',
  REMOVE_CONNECTED_USER = 'REMOVE_CONNECTED_USER',
  SET_ALL_ORDERS = 'SET_ALL_ORDERS',
  SET_FILTERED_ORDERS = 'SET_FILTERED_ORDERS',
  SET_ORDER_DETAILS = 'SET_ORDER_DETAILS',
  SET_ORDERS_FILTER = 'SET_ORDERS_FILTER',
  SET_ORDER_COMMENTS = 'SET_ORDER_COMMENTS',
  SET_DRIVER_COMMENTS = 'setDriverComments',
  SET_CLIENT_COMMENTS = 'setClientComments'
}
