import ReactGoogleAutocomplete from 'react-google-autocomplete';
import React, { ChangeEvent } from 'react';
import { GoogleAutocompleteConfig } from 'configurations/GoogleAutocompleteConfig';
import { useEnvironment } from 'hooks/useEnvironment';
import { useGlobalTranslation } from 'hooks/useGlobalTranslation';
import { Icon } from 'components/basic/Icon/Icon';
import { IInputProps } from 'components/basic/Input/InputProps';

export function InputMapAutoComplete<IFormControlName extends string>({
  t,
  className,
  formControl: {
    error,
    dirty,
    required,
    disabled,
    icon,
    name,
    value,
    label,
    autoCompleteDisabled,
    handlePlaceSelected,
    patchMarker,
    handleChange
  }
}: IInputProps<IFormControlName>): React.ReactElement {
  const { get } = useEnvironment();
  const { tGlobal } = useGlobalTranslation();
  const apiKey = get('GOOGLE_MAPS_API_KEY');

  const labelWithAsterisk = t(label) + (required ? '*' : '');
  const placeholder = icon ? labelWithAsterisk : ' ';
  const inputAutocompleteValue = autoCompleteDisabled ? 'off' : 'on';
  const googleAutocompleteClassName = `input ${icon && 'with-icon'} ${error && dirty ? 'invalid' : 'valid'}`;
  const iconClassName = `icon ${error && dirty ? 'invalid' : 'valid'}`;

  const handlePlaceChanged = (place: google.maps.places.PlaceResult) => {
    handlePlaceSelected?.(place);

    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    if (lat && lng) {
      patchMarker({ position: { lat, lng }, address: place.formatted_address });
    }
  };

  const handleInputChanged = (event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    handleChange(event);

    if (!event.target.value) {
      patchMarker({ show: false });
    }
  }

  return (
    <div className={`input-wrapper ${className}`}>
      {icon && <Icon icon={icon} iconClassName={iconClassName} />}

      <ReactGoogleAutocomplete
        id={name}
        apiKey={apiKey}
        type='map-auto-complete'
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        inputAutocompleteValue={inputAutocompleteValue}
        className={googleAutocompleteClassName}
        onPlaceSelected={handlePlaceChanged}
        onChange={handleInputChanged}
        options={GoogleAutocompleteConfig.options}
        dir='ltr'
      />

      {error && dirty && <p className='error-message'>{tGlobal(error)}</p>}
    </div>
  );
}
