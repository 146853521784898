import React, { ReactElement, ReactNode, useMemo, useReducer } from 'react';
import { initialState } from 'global-state/IGlobalState';
import { AppContext } from 'global-state/context/AppContext';
import { TReducer } from 'global-state/reducer/TReducer';
import { reducer } from 'global-state/reducer/reducer';

export function useWithAppContext(children: ReactNode, globalState = initialState) {
  function ChildrenWithAppContext(): ReactElement {
    const [state, dispatch] = useReducer<TReducer>(reducer, globalState);
    const memoizedContext = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <AppContext.Provider value={memoizedContext}>{children}</AppContext.Provider>;
  }

  return <ChildrenWithAppContext />;
}
