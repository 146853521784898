import React from 'react';
import { IInputProps } from 'components/basic/Input/InputProps';
import { InputText } from 'components/basic/Input/InputText/InputText';
import { MapPositionPicker } from 'components/basic/MapPositionPicker/MapPositionPicker';

export function InputMapPositionPicker<IFormControlName extends string>({
  t,
  formControl
}: IInputProps<IFormControlName>): React.ReactElement {
  return (
    <>
      <InputText t={t} formControl={formControl} />
      <MapPositionPicker t={t} formControl={formControl} />
    </>
  );
}
