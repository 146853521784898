import React from 'react';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import NoDataImage from 'assets/no-data.png';
import 'components/basic/NoData/NoData.scss';

interface INoDataProps {
  type: string;
}

export function NoData({ type }: INoDataProps): React.ReactElement {
  const { t } = usePrefixedTranslation('NoData');

  return (
    <div className='empty-state' data-testid='no-data-card'>
      <div className='empty-state-content'>
        <div className='empty-state-icon'>
          <img width='100px' src={NoDataImage} alt='NoData' />
        </div>
        <div className='empty-state-message'>{t(`${type}.message`)}</div>
        <div className='empty-state-help'>{t(`${type}.hint`)}</div>
      </div>
    </div>
  );
}
