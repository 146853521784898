import React from 'react';
import { EDriverStatus } from 'types/dtos/IUserDto';
import { Tag } from 'components/basic/Tag/Tag';

interface ITagProps {
  status?: EDriverStatus;
}

export function DriverStatusTag({ status }: ITagProps): React.ReactElement {
  return <Tag type={status} label={status} />;
}
