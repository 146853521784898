import { EOrderStatus } from 'types/EOrderStatus';
import { EPath } from 'types/EPath';
import { TFormControlValue } from 'types/TFormControlValue';
import { IOrderDto } from 'types/dtos/IOrderDto';
import {
  ORDER,
  ORDER_ACTIVE,
  ORDER_ARCHIVE,
  ORDER_BY_REFERENCE,
  ORDER_NOTIFICATIONS_TOGGLE,
  ORDER_SHOW_PHONE,
  ORDERS
} from 'configurations/URL';
import { setAllOrders, setFilteredOrders, setOrderDetails } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useHttp } from 'hooks/api/useHttp';
import { IFormGroup } from 'forms/group/IFormGroup';
import { showToast } from 'components/basic/Toast/Toast';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { TOrderFormControlName } from 'types/form-control-names/TOrderFormControlName';

type ISaveOrderRequestBody = Record<TOrderFormControlName | 'originLat' | 'originLng' | 'destinationLat' | 'destinationLng',
  TFormControlValue>;

export const useOrderApi = () => {
  const { dispatch } = useAppContext();
  const { get, post } = useHttp();

  return {
    saveOrder: async (formGroup: IFormGroup<TOrderFormControlName>): Promise<void> => {
      await post<never, ISaveOrderRequestBody, never>({
        url: ORDERS,
        body: {
          ...formGroup.getNameValueRecord(),
          originLat: formGroup.getMarker('origin').position.lat,
          originLng: formGroup.getMarker('origin').position.lng,
          destinationLat: formGroup.getMarker('destination').position.lat,
          destinationLng: formGroup.getMarker('destination').position.lng
        },
        onSuccess: (response) => {
          browserHistory.push(EPath.CLIENT_HOME);
          showToast.success(response.message);
        }
      });
    },
    updateOrder: async (reference: string | undefined, formGroup: IFormGroup<TOrderFormControlName>): Promise<void> => {
      await post<{ reference: string | undefined }, ISaveOrderRequestBody, never>({
        url: ORDER,
        params: { reference },
        body: {
          ...formGroup.getNameValueRecord(),
          originLat: formGroup.getMarker('origin').position.lat,
          originLng: formGroup.getMarker('origin').position.lng,
          destinationLat: formGroup.getMarker('destination').position.lat,
          destinationLng: formGroup.getMarker('destination').position.lng
        },
        onSuccess: (response) => {
          browserHistory.push(EPath.CLIENT_HOME);
          showToast.success(response.message);
        }
      });
    },
    getOrders: async (status = EOrderStatus.ACTIVE): Promise<void> => {
      await get<{ status: EOrderStatus }, IOrderDto[]>({
        url: ORDERS,
        params: {
          status
        },
        onSuccess: (response) => {
          dispatch(setAllOrders(response.value));
          dispatch(setFilteredOrders(response.value));
        }
      });
    },
    getOrderByReference: async (reference: string | undefined): Promise<void> => {
      await get<{ reference: string | undefined }, IOrderDto>({
        url: ORDER_BY_REFERENCE,
        params: {
          reference
        },
        onSuccess: (response) => {
          dispatch(setOrderDetails(response.value));
        }
      });
    },
    getMyOrder: async (reference: string | undefined): Promise<void> => {
      await get<{ reference: string | undefined }, IOrderDto>({
        url: ORDER,
        params: {
          reference
        },
        onSuccess: (response) => {
          dispatch(setOrderDetails(response.value));
        }
      });
    },
    toggleNotifications: async (reference: string | undefined): Promise<void> => {
      await post<{ reference: string | undefined }, never, IOrderDto>({
        url: ORDER_NOTIFICATIONS_TOGGLE,
        params: {
          reference
        },
        onSuccess: (response) => {
          dispatch(setOrderDetails(response.value));
        }
      });
    },
    setActiveStatus: async (reference: string | undefined): Promise<void> => {
      await post<{ reference: string | undefined }, never, IOrderDto>({
        url: ORDER_ACTIVE,
        params: {
          reference
        },
        onSuccess: (response) => {
          dispatch(setOrderDetails(response.value));
        }
      });
    },
    setArchiveStatus: async (reference: string | undefined): Promise<void> => {
      await post<{ reference: string | undefined }, never, IOrderDto>({
        url: ORDER_ARCHIVE,
        params: {
          reference
        },
        onSuccess: (response) => {
          dispatch(setOrderDetails(response.value));
        }
      });
    },
    showPhone: async (reference: string | undefined): Promise<void> => {
      await post<{ reference: string | undefined }, never, never>({
        url: ORDER_SHOW_PHONE,
        params: {
          reference
        },
        options: {
          scrollToTopDisabled: true
        }
      });
    },
  };
};
