import React from 'react';
import 'components/basic/ProgressBar/ProgressBar.scss';

export interface IProgressBarProps {
  percent: number;
  className: string;
}

export function ProgressBar({ percent, className }: IProgressBarProps): React.ReactElement {
  return (
    <div className={`progress-bar ${className}`}>
      <div className='filled-bar' style={{ width: `${percent}%` }} />
    </div>
  );
}
