import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'components/basic/Icon/Icon.scss';

type TIconPosition = 'start' | 'center' | 'end';
type TIconWidth = 'w-2' | 'w-4' | 'w-8' | 'w-16';

interface IButtonProps {
  icon: IconDefinition;
  position?: TIconPosition;
  width?: TIconWidth;
  wrapperClassName?: string;
  iconClassName?: string;
}

export function Icon({
  icon,
  position = 'center',
  width = 'w-8',
  wrapperClassName,
  iconClassName
}: IButtonProps): React.ReactElement {
  return (
    <div className={`icon-wrapper ${position} ${width} ${wrapperClassName}`}>
      <FontAwesomeIcon icon={icon} className={iconClassName} />
    </div>
  );
}
