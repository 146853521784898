import React, { ReactNode } from 'react';
import 'components/basic/Container/Container.scss';

interface IContainerProps {
  children: ReactNode;
  className?: string;
  title?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  boxed?: boolean;
}

export function Container({
  children,
  className = '',
  title = '',
  fullHeight = false,
  fullWidth = false,
  boxed = false
}: IContainerProps): React.ReactElement {
  const wrapperClassName = (() => {
    let str = className;
    if (fullHeight) {
      str += ' my-8';
    } else {
      str += ' my-28';
    }

    return str;
  })();

  const containerClassName = (() => {
    if (fullWidth) {
      return '';
    }

    let str = 'no-box';
    if (boxed) {
      str = 'box';
    }

    return str;
  })();

  return (
    <div className={`container-wrapper ${wrapperClassName}`}>
      <div className={`container ${containerClassName}`}>
        {title && (
          <h2 className='title' dir='ltr'>
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  );
}
