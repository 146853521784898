/* global google */
import { IMarker } from 'types/IMarker';
import { IPosition } from 'types/IPosition';
import { useEnvironment } from 'hooks/useEnvironment';

export const useMap = () => ({
  getAddress: async (position: IPosition) => {
    const url = 'https://maps.googleapis.com/maps/api/geocode/json';
    const { get } = useEnvironment();
    const params = {
      latlng: `${position.lat},${position.lng}`,
      key: get('GOOGLE_MAPS_API_KEY')
    };

    const address = await fetch(`${url}?${new URLSearchParams(params)}`)
      .then((response) => response.json())
      .then((response) => {
        return response.results[0].formatted_address;
      });

    return address;
  },
  fitBounds: (map: google.maps.Map | undefined, markers: IMarker[]) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position: { lat, lng } }) => bounds.extend(new google.maps.LatLng(lat, lng)));
    map?.fitBounds(bounds);
  },
  zoom: (map: google.maps.Map | undefined, value: number) => {
    const currentZoom = map?.getZoom();
    if (currentZoom) {
      map?.setZoom(currentZoom + value);
    }
  }
});
