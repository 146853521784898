import { useParams } from 'react-router-dom';
import { ReactElement } from 'react';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useFormGroup } from 'hooks/useFormGroup';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { IFormGroup } from 'forms/group/IFormGroup';
import { Form } from 'components/basic/Form/Form';
import { TCommentFormControlName } from 'types/form-control-names/TCommentFormControlName';
import { useCommentApi } from 'hooks/api/useCommentApi';

interface ICommentFormProps {
  disabled: boolean ;
}

export function CommentForm({ disabled }: ICommentFormProps): ReactElement | null {
  const { t } = usePrefixedTranslation('CommentForm');

  const { saveComment } = useCommentApi();
  const { reference } = useParams();

  const commentForm = useFormGroup<TCommentFormControlName>({
    formControlSpecs: [
      {
        name: 'contenuText',
        type: 'text-area',
        maxLength: 200,
        icon: faCircleInfo,
        disabled,
        required: true
      },

    ],
    onSubmit: (formGroup: IFormGroup<TCommentFormControlName>) => {
        saveComment(reference, formGroup);
    },
  });

  return (
      <Form t={t} formGroup={commentForm} />
  );
}
