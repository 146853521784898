
import { TFormControlValue } from 'types/TFormControlValue';

import { ICommentDto} from 'types/dtos/ICommentDto';
import {
  ORDER_SHOW_COMMENTS,
  COMMENT,
  DRIVER_SHOW_COMMENTS, CLIENT_SHOW_COMMENTS
} from 'configurations/URL';
import { setOrderComments, setDriverComments, setClientComments} from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useHttp } from 'hooks/api/useHttp';
import { IFormGroup } from 'forms/group/IFormGroup';
import { TCommentFormControlName } from 'types/form-control-names/TCommentFormControlName';


type ISaveCommentRequestBody =  Record<TCommentFormControlName,
TFormControlValue>;


export const useCommentApi = () => {
  const { dispatch } = useAppContext();
  const { get, post } = useHttp();

  return {
    showChatCommentsForOrder: async (reference: string | undefined): Promise<void> => {
      await get<{ reference: string | undefined }, ICommentDto[]>({
        url: ORDER_SHOW_COMMENTS,
        params: {
          reference
        },
        onSuccess: (response) => {
          dispatch(setOrderComments(response.value));
        }
      });
    },

    saveComment: async (reference: string | undefined, formGroup: IFormGroup<TCommentFormControlName>): Promise<void> => {
      await post<{ reference: string | undefined }, ISaveCommentRequestBody, never>({
        url: COMMENT,
        params: { reference },
        body: formGroup.getNameValueRecord(),
        onSuccess: () => {
          window.location.reload();
        }
      });
    },


    showChatCommentsForDriver: async (): Promise<void> => {
      await get<{}, ICommentDto[]>({
        url: DRIVER_SHOW_COMMENTS,
        onSuccess: (response) => {
          dispatch(setDriverComments(response.value));
        }
      });
    },

    showChatCommentsForClient: async (): Promise<void> => {
      await get<{}, ICommentDto[]>({
        url: CLIENT_SHOW_COMMENTS,
        onSuccess: (response) => {
          dispatch(setClientComments(response.value));
        }
      });
    },
  };
};
