import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { toast } from 'react-toastify';
import React from 'react';
import { EToastTheme } from 'types/EToastTheme';

export interface IToastMessageProps {
  value: string;
}

export function ToastMessage({ value }: IToastMessageProps): React.ReactElement {
  const { t } = usePrefixedTranslation('Notification');
  return <p className='text-center'>{t(value)}</p>;
}

const showToastWith = (theme: EToastTheme, message: string) => {
  toast[theme](<ToastMessage value={message} />, {
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
};

export const showToast = {
  info: (message: string) => {
    showToastWith(EToastTheme.info, message);
  },
  success: (message: string) => {
    showToastWith(EToastTheme.success, message);
  },
  warn: (message: string) => {
    showToastWith(EToastTheme.warn, message);
  },
  error: (message: string) => {
    showToastWith(EToastTheme.error, message);
  }
};
