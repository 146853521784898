import React from 'react';
import { useAppContext } from 'global-state/context/useAppContext';
import 'components/basic/PageLoader/PageLoader.scss';

export function PageLoader(): React.ReactElement | null {
  const {
    state: {
      pendingApiCalls: { critical, normal }
    }
  } = useAppContext();

  const background = critical > 0 ? 'bg-white' : 'bg-black/[0.1]';

  if (critical === 0 && normal === 0) {
    return null;
  }

  return (
    <div className={`page-loader ${background}`}>
      <div className='circle-100' />
      <div className='circle-200' />
      <div className='circle-300' />
      <div className='circle-400' />
      <div className='circle-500' />
    </div>
  );
}
