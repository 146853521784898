import React, { useEffect } from 'react';
import { useAppContext } from 'global-state/context/useAppContext';
import { useSwitchToDriverModeApi } from 'hooks/api/useSwitchToDriverModeApi';
import { useFormGroup } from 'hooks/useFormGroup';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Form } from 'components/basic/Form/Form';
import { TVehicleFormControlName } from 'types/form-control-names/TVehicleFormControlName';

export function VehicleForm(): React.ReactElement {
  const { t } = usePrefixedTranslation('VehicleForm');
  const {
    state: { connectedUser }
  } = useAppContext();
  const { getVehicle, saveVehicle } = useSwitchToDriverModeApi();

  const vehicleForm = useFormGroup<TVehicleFormControlName>({
    formControlSpecs: [
      {
        name: 'brand',
        required: true,
        maxLength: 50
      },
      {
        name: 'plateNumber',
        required: true,
        maxLength: 50
      },
      {
        name: 'image',
        type: 'image',
        required: true
      },
      {
        name: 'grayCardImageRecto',
        type: 'image',
        required: true
      },
      {
        name: 'grayCardImageVerso',
        type: 'image',
        required: true
      }
    ],
    onSubmit: saveVehicle
  });

  useEffect(() => {
    if (connectedUser?.driverStatus) {
      getVehicle(vehicleForm);
    }
  }, [connectedUser?.driverStatus]);

  return <Form t={t} formGroup={vehicleForm} />;
}
