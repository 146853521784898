import React, { MouseEvent, ReactNode } from 'react';
import 'components/basic/Button/Button.scss';

type TButton =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'DANGER'
  | 'ROUNDED'
  | 'MINIATURE'
  | 'MENU_ITEM'
  | 'OUTLINE-PRIMARY'
  | 'OUTLINE-DANGER';

interface IButtonProps {
  children: ReactNode;
  className?: string;
  type?: TButton;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
}

export function Button({
  children,
  className = '',
  type = 'PRIMARY',
  disabled = false,
  onClick
}: IButtonProps): React.ReactElement {
  const buttonClassName = `btn ${type} ${disabled ? 'disabled' : 'enabled'} ${className}`;

  return (
    <button type='button' className={buttonClassName} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
}
