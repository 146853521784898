import { IOrdersFilterDto } from 'types/dtos/IOrdersFilterDto';
import { ORDERS_BY_CURRENT_FILTER, ORDERS_FILTER_CURRENT } from 'configurations/URL';
import { setAllOrders, setOrdersFilter } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useHttp } from 'hooks/api/useHttp';
import { IOrderDto } from 'types/dtos/IOrderDto';
import { IFormGroup } from 'forms/group/IFormGroup';
import { TOrdersFilterFormControlName } from 'types/form-control-names/TOrdersFilterFormControlName';
import { useDate } from 'hooks/useDate';

export const useOrdersFilterApi = () => {
  const { dispatch } = useAppContext();
  const { get, post } = useHttp();
  const { extractDateAsStringFromRange } = useDate();

  const getOrdersByCurrentFilter = async (): Promise<void> => {
    await get<never, IOrderDto[]>({
      url: ORDERS_BY_CURRENT_FILTER,
      onSuccess: (response) => {
        dispatch(setAllOrders(response.value));
      }
    });
  };

  const getCurrentFilter = async (): Promise<void> => {
    await get<never, IOrdersFilterDto>({
      url: ORDERS_FILTER_CURRENT,
      onSuccess: async (response) => {
        dispatch(setOrdersFilter(response.value));
        await getOrdersByCurrentFilter();
      }
    });
  };

  // TODO sprint 5 add mapper for body instead
  const saveCurrentFilter = async (formGroup: IFormGroup<TOrdersFilterFormControlName>): Promise<void> => {
    await post<never, IOrdersFilterDto, IOrdersFilterDto>({
      url: ORDERS_FILTER_CURRENT,
      body: {
        origin: formGroup.getValue('origin') || '',
        originRadius: Number(formGroup.getValue('originRadius')),
        originLat: formGroup.getMarker('origin').position.lat,
        originLng: formGroup.getMarker('origin').position.lng,
        destination: formGroup.getValue('destination') || '',
        destinationRadius: Number(formGroup.getValue('destinationRadius')),
        destinationLat: formGroup.getMarker('destination').position.lat,
        destinationLng: formGroup.getMarker('destination').position.lng,
        startDeliveryDate: extractDateAsStringFromRange(formGroup.getValue('deliveryDate'), 'start'),
        endDeliveryDate: extractDateAsStringFromRange(formGroup.getValue('deliveryDate'), 'end')
      },
      onSuccess: async (response) => {
        dispatch(setOrdersFilter(response.value));
        await getOrdersByCurrentFilter();
      }
    });
  };

  return {
    getOrdersByCurrentFilter,
    getCurrentFilter,
    saveCurrentFilter
  };
};
