import { IMarker } from 'types/IMarker';
import { TDispatch } from 'types/TDispatch';
import { TFormControlValue } from 'types/TFormControlValue';
import { TPartialDispatch } from 'types/TPartialDispatch';
import { useFormControl } from 'hooks/useFormControl';
import { IFormControl } from 'forms/control/IFormControl';
import { IFormGroup } from 'forms/group/IFormGroup';
import { IFormGroupSpec } from 'forms/group/IFormGroupSpec';

export class FormGroup<TFormControlName extends string> implements IFormGroup<TFormControlName> {
  formControls: IFormControl<TFormControlName>[] = [];

  dispatch: TDispatch<IFormGroup<TFormControlName>>;

  patch: TPartialDispatch<IFormGroup<TFormControlName>>;

  onSubmit?: (formGroup: IFormGroup<TFormControlName>) => void;

  constructor(formGroupSpec: IFormGroupSpec<TFormControlName>) {
    this.dispatch = () => {};
    this.patch = () => {};
    this.initFormControls(formGroupSpec);
    this.onSubmit = formGroupSpec.onSubmit;
  }

  handleSubmit = () => {
    this.onSubmit?.(this);
  };

  getNameValueRecord = (): Record<TFormControlName, TFormControlValue> => {
    const { name, value } = this.formControls[0];

    let nameValueRecord = { [name]: value } as Record<TFormControlName, TFormControlValue>;
    this.formControls.forEach((formControl) => {
      nameValueRecord = { ...nameValueRecord, [formControl.name]: formControl.value };
    });

    return nameValueRecord;
  };

  getValue = (formControlName: TFormControlName): string => {
    const formControlFound = this.findFormControl(formControlName);
    return formControlFound.value;
  };

  getMarker = (formControlName: TFormControlName): IMarker => {
    const formControlFound = this.findFormControl(formControlName);

    if (formControlFound.type !== 'map-position-picker') {
      throw Error(`Cannot get marker from form control : ${formControlName}`);
    }

    return formControlFound.marker;
  };

  patchMarker = (formControlName: TFormControlName, partialMarker: Partial<IMarker>): void => {
    const formControlFound = this.findFormControl(formControlName);

    if (formControlFound.type !== 'map-position-picker') {
      throw Error(`Cannot get marker from form control : ${formControlName}`);
    }

    formControlFound.patchMarker(partialMarker);
  };

  updateFormValues = (nameValueRecord: Record<TFormControlName, TFormControlValue>): void => {
    if (!nameValueRecord) {
      throw Error(`Cannot update form values with : ${nameValueRecord}`);
    }

    Object.entries(nameValueRecord).forEach(([key, value]) => {
      if (value) {
        const formControl = this.findFormControl(key);
        formControl.updateValue(`${value}`);
      }
    });
  };

  isValid = (): boolean => {
    const formControlWithError = this.formControls.find((formControl) => formControl.error);
    return !formControlWithError;
  };

  isNotValid = (): boolean => {
    return !this.isValid();
  };

  private initFormControls = (formGroupSpec: IFormGroupSpec<TFormControlName>): void => {
    this.formControls = formGroupSpec.formControlSpecs.map((formControlSpec) =>
      useFormControl<TFormControlName>({ ...formControlSpec })
    );
  };

  private findFormControl = (name: string): IFormControl<TFormControlName> => {
    const formControlFound = this.formControls.find((formControl) => formControl.name === name);

    if (!formControlFound) {
      throw Error(`Form control with name : ${name} doesn't exist`);
    }

    return formControlFound;
  };
}
