import React from 'react';
import { useGlobalTranslation } from 'hooks/useGlobalTranslation';
import { Icon } from 'components/basic/Icon/Icon';
import { IInputProps } from 'components/basic/Input/InputProps';

export function InputText<IFormControlName extends string>({
                                                             t,
                                                             formControl: {
                                                               name,
                                                               label,
                                                               type,
                                                               value,
                                                               minValue,
                                                               maxValue,
                                                               error,
                                                               dirty,
                                                               icon,
                                                               disabled,
                                                               required,
                                                               autoCompleteDisabled,
                                                               handleChange,
                                                               handleFocus
                                                             }
                                                           }: IInputProps<IFormControlName>): React.ReactElement {
  const { tGlobal } = useGlobalTranslation();
  const labelWithAsterisk = t(label) + (required ? '*' : '');
  const isRangeNumber = type === 'range';

  return (
    <div className='input-wrapper'>
      {icon && <Icon icon={icon} iconClassName={`icon ${error && dirty ? 'invalid' : 'valid'}`} />}
      <input
        id={name}
        type={type === 'phone' ? 'tel' : type}
        autoComplete={autoCompleteDisabled ? 'off' : 'on'}
        disabled={disabled}
        value={value}
        min={minValue}
        max={maxValue}
        className={`input ${icon && 'with-icon'} ${error && dirty ? 'invalid' : 'valid'} ${isRangeNumber && 'no-padding-x no-margin-x'}`}
        placeholder={icon ? labelWithAsterisk : ' '}
        onChange={handleChange}
        onFocus={handleFocus}
        dir='ltr'
      />

      {(!icon || isRangeNumber) && (
        <label htmlFor={name} className={`label label-float ${error && dirty ? 'invalid' : 'valid'} ${isRangeNumber && 'no-padding-x no-margin-x'}`}>
          {labelWithAsterisk}{isRangeNumber && (<> : <span className='value'>{value}</span></>)}
        </label>
      )}

      {error && dirty && <p className='error-message'>{tGlobal(error, { minValue })}</p>}
    </div>
  );
}
