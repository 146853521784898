import React from 'react';
import { useGlobalTranslation } from 'hooks/useGlobalTranslation';
import { Icon } from 'components/basic/Icon/Icon';
import { IInputProps } from 'components/basic/Input/InputProps';

export function InputTextArea<IFormControlName extends string>({
  t,
  formControl: { name, label, value, error, dirty, icon, disabled, required, autoCompleteDisabled, handleChange }
}: IInputProps<IFormControlName>): React.ReactElement {
  const { tGlobal } = useGlobalTranslation();
  const labelWithAsterisk = t(label) + (required ? '*' : '');

  return (
    <div className='input-wrapper'>
      {icon && <Icon icon={icon} iconClassName={`icon ${error && dirty ? 'invalid' : 'valid'}`} />}
      <textarea
        id={name}
        autoComplete={autoCompleteDisabled ? 'off' : 'on'}
        disabled={disabled}
        value={value}
        className={`input textarea ${icon && 'with-icon'} ${error && dirty ? 'invalid' : 'valid'}`}
        placeholder={icon ? labelWithAsterisk : ' '}
        dir='ltr'
        onChange={handleChange}
      />

      {!icon && (
        <label htmlFor={name} className={`label label-float ${error && dirty ? 'invalid' : 'valid'}`}>
          {labelWithAsterisk}
        </label>
      )}

      {error && dirty && <p className='error-message'>{tGlobal(error)}</p>}
    </div>
  );
}
