export enum EPath {
  ROOT = '/',
  CLIENT_HOME = '/my-orders',
  HOME = '/home',
  DRIVER_HOME = '/driver-home',
  DRIVER_MODE_FORM = '/driver-mode-form',
  DRIVERS_MANAGEMENT = '/drivers-management',
  ORDER_FORM = '/order/form',
  MY_ORDER_DETAILS = '/client-mode/details',
  ORDER_DETAILS = '/order/details',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  VERIFICATION_CODE = '/code-verification',
  ANY = '*'
}
