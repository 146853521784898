import React, { useState } from 'react';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EClientStatus, EDriverStatus } from 'types/dtos/IUserDto';
import { useFormControl } from 'hooks/useFormControl';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { useUuid } from 'hooks/useUuid';
import { Button } from 'components/basic/Button/Button';
import { Container } from 'components/basic/Container/Container';
import { Input } from 'components/basic/Input/Input';
import { DriverModal } from 'components/pages/DriversManagement/DriverModal/DriverModal';
import { DriverStatusTag } from 'components/pages/DriversManagement/DriverStatusTag/DriverStatusTag';
import 'components/pages/DriversManagement/DriversManagement.scss';

export function DriversManagement(): React.ReactElement {
  const { t } = usePrefixedTranslation('DriversManagement');
  const { generate } = useUuid();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const searchFormControl = useFormControl({
    name: 'search',
    icon: faMagnifyingGlass
  });

  const toggle = () => setIsModalOpen(!isModalOpen);

  return (
    <Container fullWidth title={t('title')}>
      <div className='relative overflow-x-auto shadow-md sm:rounded-lg lg:mx-20'>
        <div className='relative flex justify-between items-stretch p-5'>
          <div className='relative'>
            <Input t={t} formControl={searchFormControl} />
          </div>
          <div>
            <Button type='SECONDARY' className='h-full'>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <table className='w-full text-sm text-left text-gray-500'>
          <thead className='text-xs text-gray-700 uppercase bg-gray-100'>
            <tr>
              <th scope='col' className='px-6 py-3'>
                {t('driverRow')}
              </th>
              <th scope='col' className='px-6 py-3'>
                {t('phoneNumberRow')}
              </th>
              <th scope='col' className='hidden md:block px-6 py-3'>
                {t('emailRow')}
              </th>
              <th scope='col' className='text-center px-6 py-3'>
                {t('statusRow')}
              </th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                phone: '0639246929',
                email: 'Mohamed.Arabi@gmail.com',
                firstName: 'Mohamed Arabi',
                clientStatus: EClientStatus.ACTIVE,
                driverStatus: EDriverStatus.STEP_1
              }
            ].map((driver) => (
              <tr key={generate()} className='bg-white border-b' onClick={toggle}>
                <th scope='row' className='font-medium text-gray-900 px-6 py-4'>
                  {driver.firstName}
                </th>
                <td className='px-6 py-4'>{driver.phone}</td>
                <td className='hidden md:block px-6 py-4'>{driver.email}</td>
                <td className='text-center px-6 py-4'>
                  <DriverStatusTag status={driver.driverStatus} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DriverModal isOpen={isModalOpen} toggle={toggle} />
    </Container>
  );
}
