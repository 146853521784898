import React from 'react';
import { InputDate } from 'components/basic/Input/InputDate/InputDate';
import { InputImage } from 'components/basic/Input/InputImage/InputImage';
import { InputMapAutoComplete } from 'components/basic/Input/InputMapAutoComplete/InputMapAutoComplete';
import { InputMapPositionPicker } from 'components/basic/Input/InputMapPositionPicker/InputMapPositionPicker';
import { InputPin } from 'components/basic/Input/InputPin/InputPin';
import { IInputProps } from 'components/basic/Input/InputProps';
import { InputText } from 'components/basic/Input/InputText/InputText';
import { InputTextArea } from 'components/basic/Input/InputTextArea/InputTextArea';
import 'components/basic/Input/Input.scss';
import { InputDateRange } from 'components/basic/Input/InputDateRange/InputDateRange';

export function Input<IFormControlName extends string>({
  t,
  formControl
}: IInputProps<IFormControlName>): React.ReactElement {
  if (formControl.type === 'image') {
    return <InputImage t={t} formControl={formControl} />;
  }

  if (formControl.type === 'date') {
    return <InputDate t={t} formControl={formControl} />;
  }

  if (formControl.type === 'date-range') {
    return <InputDateRange t={t} formControl={formControl} />;
  }

  if (formControl.type === 'pin') {
    return <InputPin t={t} formControl={formControl} />;
  }

  if (formControl.type === 'map-auto-complete') {
    return <InputMapAutoComplete t={t} formControl={formControl} />;
  }

  if (formControl.type === 'map-position-picker') {
    return <InputMapPositionPicker t={t} formControl={formControl} />;
  }

  if (formControl.type === 'text-area') {
    return <InputTextArea t={t} formControl={formControl} />;
  }

  return <InputText t={t} formControl={formControl} />;
}
