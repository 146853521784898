export enum EValidatorError {
  VALID = '',
  REQUIRED = 'Validator.required',
  PHONE = 'Validator.phone',
  EMAIL = 'Validator.email',
  MAX_LENGTH = 'Validator.maxLength',
  EXACT_LENGTH = 'Validator.exactLength',
  MIN_VALUE = 'Validator.minValue',
  MIN_DATE_TODAY = 'Validator.minDateToday',
  IMAGE_MAX_SIZE = 'Validator.imageMaxSize'
}
