import PinInput from 'react-pin-input';
import React from 'react';
import { IInputProps } from 'components/basic/Input/InputProps';

export function InputPin<IFormControlName extends string>({
  formControl: { exactLength, disabled, value, updateValue }
}: IInputProps<IFormControlName>): React.ReactElement {
  return (
    <div className='input-wrapper pin' dir='ltr'>
      <PinInput
        length={exactLength || 4}
        disabled={disabled}
        initialValue={value}
        onChange={updateValue}
        onComplete={updateValue}
        type='numeric'
        inputMode='number'
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </div>
  );
}
