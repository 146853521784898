import { Navigate } from 'react-router-dom';
import React from 'react';
import { EAccess } from 'types/EAccess';
import { EPath } from 'types/EPath';
import { EClientStatus, EConnectedAs, EDriverStatus } from 'types/dtos/IUserDto';
import { useAppContext } from 'global-state/context/useAppContext';
import { useLocalStorage } from 'hooks/useLocalStorage';

interface IGuardProps {
  element: React.ReactElement;
  access: EAccess;
}

export function Guard({ element, access }: IGuardProps): React.ReactElement | null {
  const { getItem } = useLocalStorage();
  const {
    state: { connectedUser }
  } = useAppContext();

  const isConnected = !!getItem('token');
  const isConnectedAsDriver = isConnected && connectedUser?.connectedAs === EConnectedAs.DRIVER;
  const isActiveDriver = isConnectedAsDriver && connectedUser?.driverStatus === EDriverStatus.ACTIVE;
  const isProfileCompleted = isConnected && connectedUser?.clientStatus === EClientStatus.ACTIVE;

  if (access !== EAccess.LOGGED_OUT_ONLY && !isConnected) {
    return <Navigate to={EPath.SIGN_IN} />;
  }
  if(connectedUser){
    if ((access === EAccess.LOGGED_OUT_ONLY || access === EAccess.CLIENT_ONLY) && isActiveDriver) {
      return <Navigate to={EPath.DRIVER_HOME} />;
    }

    if ((access === EAccess.LOGGED_OUT_ONLY || access === EAccess.DRIVER_ONLY) && isConnected && !isActiveDriver) {
      console.log(isConnected);
      return <Navigate to={EPath.HOME} />;
    }
    if (access === EAccess.ACTIVE_CLIENT_ONLY && !isProfileCompleted) {
      return <Navigate to={EPath.HOME} />;
    }
  }

  return element;
}
