import { EPath } from 'types/EPath';
import { TFormControlValue } from 'types/TFormControlValue';
import { IDriverLicenseDto, mapToDriverLicenseForm } from 'types/dtos/IDriverLicenseDto';
import { EConnectedAs, IUserDto } from 'types/dtos/IUserDto';
import { IVehicleDto, mapToVehicleForm } from 'types/dtos/IVehicleDto';
import { DRIVER_LICENSE, USERS_CURRENT_AS_CLIENT, USERS_CURRENT_AS_DRIVER, VEHICLE } from 'configurations/URL';
import { setConnectedUser } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useHttp } from 'hooks/api/useHttp';
import { IFormGroup } from 'forms/group/IFormGroup';
import { showToast } from 'components/basic/Toast/Toast';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import { TDriverLicenseFormControlName } from 'types/form-control-names/TDriverLicenseFormControlName';
import { TVehicleFormControlName } from 'types/form-control-names/TVehicleFormControlName';

export const useSwitchToDriverModeApi = () => {
  const { dispatch } = useAppContext();
  const { get, post } = useHttp();

  return {
    getDriverLicense: async (formGroup: IFormGroup<TDriverLicenseFormControlName>): Promise<void> => {
      await get<never, IDriverLicenseDto>({
        url: DRIVER_LICENSE,
        onSuccess: (response) => {
          formGroup.updateFormValues(mapToDriverLicenseForm(response.value));
        }
      });
    },
    saveDriverLicense: async <TFormControlName extends string>(
      formGroup: IFormGroup<TFormControlName>
    ): Promise<void> => {
      await post<never, Record<TFormControlName, TFormControlValue>, IUserDto>({
        url: DRIVER_LICENSE,
        body: formGroup.getNameValueRecord(),
        onSuccess: (response) => {
          showToast.success(response.message);
          dispatch(setConnectedUser(response.value));
        }
      });
    },
    getVehicle: async (formGroup: IFormGroup<TVehicleFormControlName>): Promise<void> => {
      await get<never, IVehicleDto>({
        url: VEHICLE,
        onSuccess: (response) => {
          formGroup.updateFormValues(mapToVehicleForm(response.value));
        }
      });
    },
    saveVehicle: async <TFormControlName extends string>(formGroup: IFormGroup<TFormControlName>): Promise<void> => {
      await post<never, Record<TFormControlName, TFormControlValue>, IUserDto>({
        url: VEHICLE,
        body: formGroup.getNameValueRecord(),
        onSuccess: (response) => {
          browserHistory.push(EPath.CLIENT_HOME);
          showToast.success(response.message);
          dispatch(setConnectedUser(response.value));
        }
      });
    },
    setConnectedAs: async (connectedAs: EConnectedAs): Promise<void> => {
      let url: string;
      if (connectedAs === EConnectedAs.DRIVER) {
        url = USERS_CURRENT_AS_DRIVER;
      } else {
        url = USERS_CURRENT_AS_CLIENT;
      }

      await post<never, never, IUserDto>({
        url,
        onSuccess: (response) => {
          browserHistory.push(EPath.ROOT);
          dispatch(setConnectedUser(response.value));
        }
      });
    }
  };
};
