import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import React from 'react';
import {
  faArrowRightFromBracket, faBox,
  faHome,
  faMapLocationDot,
  faTruck,
  faUser,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { EPath } from 'types/EPath';
import { EClientStatus, EConnectedAs, EDriverStatus } from 'types/dtos/IUserDto';
import { removeConnectedUser, toggleIsMenuOpen } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useSwitchToDriverModeApi } from 'hooks/api/useSwitchToDriverModeApi';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Button } from 'components/basic/Button/Button';
import { Drawer } from 'components/basic/Drawer/Drawer';
import { Icon } from 'components/basic/Icon/Icon';
import { ProgressBar } from 'components/basic/ProgressBar/ProgressBar';
import { showToast } from 'components/basic/Toast/Toast';
import 'components/layout/SideMenu/SideMenu.scss';

export function SideMenu(): React.ReactElement {
  const { removeItem } = useLocalStorage();
  const navigate = useNavigate();
  const { t } = usePrefixedTranslation('SideMenu');
  const { setConnectedAs } = useSwitchToDriverModeApi();
  const {
    state: { connectedUser, isMenuOpen },
    dispatch
  } = useAppContext();
  const isActiveClient = connectedUser?.clientStatus === EClientStatus.ACTIVE;
  const isActiveDriver = connectedUser?.driverStatus === EDriverStatus.ACTIVE;
  const isConnectedAsClient = connectedUser?.connectedAs === EConnectedAs.CLIENT;
  const isConnectedAsDriver = connectedUser?.connectedAs === EConnectedAs.DRIVER;

  const toggleMenu = () => {
    dispatch(toggleIsMenuOpen());
  };

  const navigateToSignUp = () => {
    toggleMenu();
    scroll.scrollToTop();
    navigate(EPath.SIGN_UP);
  };

  const navigateToDriverModeForm = () => {
    toggleMenu();
    navigate(EPath.DRIVER_MODE_FORM);
  };

  const navigateToDriverMode = () => {
    toggleMenu();
    if (!isActiveDriver) {
      navigate(EPath.DRIVER_MODE_FORM);
      return;
    }
    setConnectedAs(EConnectedAs.DRIVER);
  };

  const navigateToClientMode = () => {
    toggleMenu();
    setConnectedAs(EConnectedAs.CLIENT);
  };

  const signOut = () => {
    toggleMenu();
    scroll.scrollToTop();
    removeItem('token');
    dispatch(removeConnectedUser());
    navigate(EPath.SIGN_IN);
  };

  const navigateToOrder = () => {
    toggleMenu();
    scroll.scrollToTop();
    navigate(EPath.ORDER_FORM);
  };

  const navigateToHome = () => {
    toggleMenu();
    scroll.scrollToTop();
    if (connectedUser?.connectedAs === EConnectedAs.CLIENT) {
      navigate(EPath.HOME);
    }
    if (connectedUser?.connectedAs === EConnectedAs.DRIVER) {
      navigate(EPath.DRIVER_HOME);
    }
  };

  const navigateToMyOrders = () => {
    toggleMenu();
    scroll.scrollToTop();
    if (connectedUser?.connectedAs === EConnectedAs.CLIENT) {
      navigate(EPath.CLIENT_HOME);
    }
    if (connectedUser?.connectedAs === EConnectedAs.DRIVER) {
      navigate(EPath.DRIVER_HOME);
    }
  };

  const handleDriverModeClick = () => {
    if (!isActiveClient) {
      showToast.warn(t('cannotPassDriver'));
      navigateToSignUp();
    } else {
      navigateToDriverMode();
    }
  };

  const handleToOrderClick = () => {
    if (!isActiveClient) {
      showToast.warn(t('cannotPassToOrder'));
      navigateToSignUp();
    } else {
      navigateToOrder();
    }
  };

  return (
    <Drawer title={connectedUser?.phone} isOpen={isMenuOpen} toggle={toggleMenu}>
      <div className='side-menu'>
        {/* ******* Menu element ***************************************************************************** */}
        <hr />
        <Button type='MENU_ITEM' onClick={navigateToHome}>
          <Icon icon={faHome} position='start' wrapperClassName='inline-flex' />
          <span>{t('home')}</span>
        </Button>
        <hr />

        {/* ******* Menu element ***************************************************************************** */}
        {isConnectedAsClient && (
            <>
              <Button type='MENU_ITEM' onClick={navigateToMyOrders}>
                <Icon icon={faBox} position='start' wrapperClassName='inline-flex' />
                <span>{t('myorders')}</span>
              </Button>
              <hr />
            </>
        )}

        {/* ******* Menu element ***************************************************************************** */}
        <Button type='MENU_ITEM' onClick={navigateToSignUp}>
          <Icon icon={faUser} position='start' wrapperClassName='inline-flex' />
          <span>{t('personalInformation')}</span>

          <ProgressBar className='personal-info-bar' percent={isActiveClient ? 100 : 20} />
          <div className='personal-info-legend'>
            {isActiveClient ? t('completeInfoSuccess') : t('completeInfoWarning')}
          </div>
        </Button>
        <hr />

        {/* ******* Menu element ***************************************************************************** */}
        {isConnectedAsClient && (
          <>
            <Button type='MENU_ITEM' onClick={handleToOrderClick}>
              <Icon icon={faMapLocationDot} position='start' wrapperClassName='inline-flex' />
              <span>{t('order')}</span>
            </Button>
            <hr />
          </>
        )}

        {/* ******* Menu element ***************************************************************************** */}
        {isConnectedAsDriver && (
          <>
            <Button type='MENU_ITEM' onClick={navigateToDriverModeForm}>
              <Icon icon={faTruck} position='start' wrapperClassName='inline-flex' />
              <span>{t('editDriverForms')}</span>
            </Button>
            <hr />
          </>
        )}

        {/* ******* Menu element ***************************************************************************** */}
        {isConnectedAsDriver && (
          <>
            <Button type='MENU_ITEM' onClick={navigateToClientMode}>
              <Icon icon={faUserTie} position='start' wrapperClassName='inline-flex' />
              <span>{t('clientMode')}</span>
            </Button>
            <hr />
          </>
        )}

        {/* ******* Menu element ***************************************************************************** */}
        {isConnectedAsClient && (
          <>
            <Button type='MENU_ITEM' onClick={handleDriverModeClick}>
              <Icon icon={faTruck} position='start' wrapperClassName='inline-flex' />
              <span>{t('driverMode')}</span>
            </Button>
            <hr />
          </>
        )}

        {/* ******* Menu element ***************************************************************************** */}
        <Button type='MENU_ITEM' onClick={signOut}>
          <Icon icon={faArrowRightFromBracket} position='start' wrapperClassName='inline-flex' />
          <span>{t('signOut')}</span>
        </Button>
        <hr />
      </div>
    </Drawer>
  );
}
