import { useState } from 'react';
import { IDispatcher } from 'types/IDispatcher';

export const useStateWithDispatchers = <T extends IDispatcher<T>>(initialState: T) => {
  const [state, setState] = useState<T>(initialState);

  state.dispatch = (entireState: T) => {
    setState({ ...entireState });
  };

  state.patch = (partialState: Partial<T>) => {
    setState((previousState) => ({ ...previousState, ...partialState }));
  };

  return state;
};
