import React, { useEffect } from 'react';
import { faHashtag, faLocationDot, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EOrderStatus } from 'types/EOrderStatus';
import { EPath } from 'types/EPath';
import { setFilteredOrders } from 'global-state/action/actions';
import { useAppContext } from 'global-state/context/useAppContext';
import { useOrderApi } from 'hooks/api/useOrderApi';
import { useFormGroup } from 'hooks/useFormGroup';
import { usePrefixedTranslation } from 'hooks/usePrefixedTranslation';
import { Button } from 'components/basic/Button/Button';
import { CheckBox } from 'components/basic/CheckBox/CheckBox';
import { Form } from 'components/basic/Form/Form';
import { browserHistory } from 'components/layout/CustomBrowserRouter/CustomBrowserRouter';
import 'components/pages/ClientHome/OrdersFilter/OrdersFilter.scss';
import { showToast } from 'components/basic/Toast/Toast';
import { EClientStatus } from 'types/dtos/IUserDto';

interface IOrdersFilterProps {
  visible: boolean;
}

type TFormControlName = 'filterByReference' | 'filterByAddress';

export function OrdersFilter({ visible }: IOrdersFilterProps): React.ReactElement {
  const { t } = usePrefixedTranslation('OrdersFilter');
  const { getOrders } = useOrderApi();
  const {
    dispatch,
    state: { connectedUser, allOrders }
  } = useAppContext();
  const isNotActiveClient = connectedUser?.clientStatus !== EClientStatus.ACTIVE;

  const filterForm = useFormGroup<TFormControlName>({
    formControlSpecs: [
      {
        name: 'filterByReference',
        icon: faHashtag
      },
      {
        name: 'filterByAddress',
        icon: faLocationDot
      }
    ]
  });

  const navigateToAddOrder = () => {
    if (isNotActiveClient) {
      showToast.warn(t('cannotAddOrder'));
      browserHistory.push(EPath.SIGN_UP)
    } else {
      browserHistory.push(EPath.ORDER_FORM);
    }  
  };

  const handleCheckBoxChange = (checked: boolean) => {
    const status = checked ? EOrderStatus.ARCHIVE : EOrderStatus.ACTIVE;
    getOrders(status);
  };

  useEffect(() => {
    const filterByReference = (value: string) => {
      const filteredOrders = allOrders.filter((order) => {
        const orderReference = order.reference.trim().toLowerCase();
        const inputReference = value.trim().toLowerCase();

        return orderReference.includes(inputReference);
      });

      dispatch(setFilteredOrders(filteredOrders));
    };

    const filterByAddress = (value: string) => {
      const filteredOrders = allOrders.filter((order) => {
        const orderOrigin = order.origin.trim().toLowerCase();
        const orderDestination = order.destination.trim().toLowerCase();
        const inputAddress = value.trim().toLowerCase();

        return orderOrigin.includes(inputAddress) || orderDestination.includes(inputAddress);
      });

      dispatch(setFilteredOrders(filteredOrders));
    };

    filterForm.formControls[0].onChange = filterByReference;
    filterForm.formControls[1].onChange = filterByAddress;
  }, [allOrders]);

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      <div className='header-filter' dir='ltr'>
        <CheckBox leftLabel={t('active')} rightLabel={t('archive')} onChange={handleCheckBoxChange} />
        <div className='w-12'>
          <Button  type='OUTLINE-PRIMARY' onClick={navigateToAddOrder}>
            <FontAwesomeIcon className='text-xl w-5 h-5' icon={faPlus} />
          </Button>
        </div>
      </div>

      {visible && (
        <div className='expanded-filter'>
          <Form t={t} formGroup={filterForm} showSubmit={false} />
        </div>
      )}
    </>
  );
}
