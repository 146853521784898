import React, { ReactNode } from 'react';
import { Container } from 'components/basic/Container/Container';
import 'components/basic/Modal/Modal.scss';

interface IModalProps {
  children: ReactNode;
  title: string;
  isOpen?: boolean;
  toggle?: () => void;
}

export function Modal({ children, title, isOpen = false, toggle }: IModalProps): React.ReactElement | null {
  const handleClickOutside = () => {
    toggle?.();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal'>
      <div className='modal-dead' role='presentation' onClick={handleClickOutside} />
      <div className='modal-content-wrapper'>
        <div className='modal-content'>
          <div className='modal-container-wrapper'>
            <Container className='modal-container' title={title}>
              {children}
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
