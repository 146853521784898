import { Route, Routes } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { EAccess } from 'types/EAccess';
import { EPath } from 'types/EPath';
import { useUuid } from 'hooks/useUuid';
import { Guard } from 'components/layout/Guard/Guard';
import { ClientHome } from 'components/pages/ClientHome/ClientHome';
import { Home } from 'components/pages/Home/Home';
import { DriverHome } from 'components/pages/DriverHome/DriverHome';
import { DriversManagement } from 'components/pages/DriversManagement/DriversManagement';
import { OrderDetails } from 'components/pages/OrderDetails/OrderDetails';
import { OrderForm } from 'components/pages/OrderForm/OrderForm';
import { SignIn } from 'components/pages/SignIn/SignIn';
import { SignUp } from 'components/pages/SignUp/SignUp';
import { SwitchToDriverMode } from 'components/pages/SwitchToDriverMode/SwitchToDriverMode';
import { VerificationCode } from 'components/pages/VerificationCode/VerificationCode';

export interface IRoute {
  path: string;
  access: EAccess;
  element: ReactElement;
}

export function Router(): React.ReactElement {
  const { generate } = useUuid();
  const routes: IRoute[] = [
    {
      path: EPath.ROOT,
      access: EAccess.LOGGED_OUT_ONLY,
      element: <SignIn />
    },
    {
      path: EPath.SIGN_IN,
      access: EAccess.LOGGED_OUT_ONLY,
      element: <SignIn />
    },
    {
      path: EPath.VERIFICATION_CODE,
      access: EAccess.LOGGED_OUT_ONLY,
      element: <VerificationCode />
    },
    {
      path: EPath.CLIENT_HOME,
      access: EAccess.CLIENT_ONLY,
      element: <ClientHome />
    },
    {
      path: EPath.HOME,
      access: EAccess.CLIENT_ONLY,
      element: <Home />
    },
    {
      path: `${EPath.MY_ORDER_DETAILS}/:reference`,
      access: EAccess.CLIENT_ONLY,
      element: <OrderDetails type='client-mode' />
    },
    {
      path: `${EPath.ORDER_DETAILS}/:reference`,
      access: EAccess.DRIVER_ONLY,
      element: <OrderDetails type="driver-mode" />
    },
    {
      path: `default${EPath.ORDER_DETAILS}/:reference`,
      access: EAccess.CLIENT_ONLY,
      element: <OrderDetails type="default"/>
    },
    {
      path: EPath.ORDER_FORM,
      access: EAccess.CLIENT_ONLY,
      element: <OrderForm />
    },
    {
      path: `${EPath.ORDER_FORM}/:reference`,
      access: EAccess.CLIENT_ONLY,
      element: <OrderForm />
    },
    {
      path: EPath.DRIVER_HOME,
      access: EAccess.DRIVER_ONLY,
      element: <DriverHome />
    },
    {
      path: EPath.SIGN_UP,
      access: EAccess.LOGGED_IN_ONLY,
      element: <SignUp />
    },
    {
      path: EPath.DRIVER_MODE_FORM,
      access: EAccess.ACTIVE_CLIENT_ONLY,
      element: <SwitchToDriverMode />
    },
    {
      path: EPath.DRIVERS_MANAGEMENT,
      access: EAccess.LOGGED_IN_ONLY,
      element: <DriversManagement />
    },
    {
      path: EPath.ANY,
      access: EAccess.LOGGED_OUT_ONLY,
      element: <SignIn />
    }
  ];

  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route key={generate()} path={route.path} element={<Guard access={route.access} element={route.element} />} />
        );
      })}
    </Routes>
  );
}
