import { TOptions } from 'i18next';
import { TFunction, useTranslation } from 'react-i18next';

interface IUseTranslation {
  t: TFunction<'translation'>;
}

export const usePrefixedTranslation = (prefix: string): IUseTranslation => {
  const { t } = useTranslation();
  return {
    t: (key: string, options?: TOptions<{}>) => t(`${prefix}.${key}`, options)
  };
};
